import {
  FETCH_LIST_TRACKING_ANALYSIS,
  FETCH_LIST_TRACKING_ANALYSIS_SUCCESS,
  FETCH_LIST_TRACKING_ANALYSIS_FAILED,
} from "../actions/trackingAnalysis.action";

const INITIAL_STATE = {
  trackingAnalysis: {
    data: []
  },
  error: {},
  loading: false,
};

export default function degreeReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LIST_TRACKING_ANALYSIS:
      return Object.assign({}, state, {
        loading: true,
      });
    case FETCH_LIST_TRACKING_ANALYSIS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        trackingAnalysis: {
          ...payload,
        },
      });
    case FETCH_LIST_TRACKING_ANALYSIS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
