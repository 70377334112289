export const GET_LANGUAGES_SYSTEM = "[LANGUAGE SYSTEM] GET_LANGUAGES_SYSTEM";
export const GET_LANGUAGES_SYSTEM_SUCCESS = "[LANGUAGE SYSTEM] GET_LANGUAGES_SYSTEM_SUCCESS";
export const GET_LANGUAGES_SYSTEM_FAILED = "[LANGUAGE SYSTEM] GET_LANGUAGES_SYSTEM_FAILED";

export const CREATE_LANGUAGE_SYSTEM = "[LANGUAGE SYSTEM] CREATE_LANGUAGE_SYSTEM";
export const CREATE_LANGUAGE_SYSTEM_SUCCESS = "[LANGUAGE SYSTEM] CREATE_LANGUAGE_SYSTEM_SUCCESS";
export const CREATE_LANGUAGE_SYSTEM_FAILED = "[LANGUAGE SYSTEM] CREATE_LANGUAGE_SYSTEM_FAILED";

export const UPDATE_LANGUAGE_SYSTEM = "[LANGUAGE SYSTEM] UPDATE_LANGUAGE_SYSTEM";
export const UPDATE_LANGUAGE_SYSTEM_SUCCESS = "[LANGUAGE SYSTEM] UPDATE_LANGUAGE_SYSTEM_SUCCESS";
export const UPDATE_LANGUAGE_SYSTEM_FAILED = "[LANGUAGE SYSTEM] UPDATE_LANGUAGE_SYSTEM_FAILED";

export const DELETE_LANGUAGE_SYSTEM = "[LANGUAGE SYSTEM] DELETE_LANGUAGE_SYSTEM";
export const DELETE_LANGUAGE_SYSTEM_SUCCESS = "[LANGUAGE SYSTEM] DELETE_LANGUAGE_SYSTEM_SUCCESS";
export const DELETE_LANGUAGE_SYSTEM_FAILED = "[LANGUAGE SYSTEM] DELETE_LANGUAGE_SYSTEM_FAILED";
