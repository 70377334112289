import {
  GET_CURRENCIES,
  GET_CURRENCIES_FAILED,
  GET_CURRENCIES_SUCCESS,
  CREATE_CURRENCY,
  CREATE_CURRENCY_FAILED,
  CREATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAILED,
  UPDATE_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_FAILED,
  DELETE_CURRENCY_SUCCESS
} from "../actions/currencyManagement.action";

const INITIAL_STATE = {
  data: [],
  error: {},
  loading: false,
};

export default function currencyManagementReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CURRENCIES:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_CURRENCIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: payload,
      });
    case GET_CURRENCIES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case CREATE_CURRENCY:
      return Object.assign({}, state, {
        loading: true,
      });
    case CREATE_CURRENCY_SUCCESS:
      return state;
    case CREATE_CURRENCY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case UPDATE_CURRENCY:
      return Object.assign({}, state, {
        loading: true,
      });
    case UPDATE_CURRENCY_SUCCESS:
      return state;
    case UPDATE_CURRENCY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case DELETE_CURRENCY:
      return Object.assign({}, state, {
        loading: true,
      });
    case DELETE_CURRENCY_SUCCESS:
      return state;
    case DELETE_CURRENCY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}