export const GET_ORDER_LIST = "[ORDER_MANAGEMENT] GET_ORDERS";
export const GET_ORDER_LIST_SUCCESS = "[ORDER_MANAGEMENT] GET_ORDER_SUCCESS";
export const GET_ORDER_LIST_FAILED = "[ORDER_MANAGEMENT] GET_ORDER_FAILED";
export const CLEAR_ORDER_MANAGEMENT_STATE =
  "[ORDER_MANAGEMENT] CLEAR_ORDER_MANAGEMENT_STATE";

export const GET_ORDER_DETAIL = "[ORDER_MANAGEMENT] GET_ORDER_DETAIL";
export const GET_ORDER_DETAIL_SUCCESS =
  "[ORDER_MANAGEMENT] GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_FAILED =
  "[ORDER_MANAGEMENT] GET_ORDER_DETAIL_FAILED";
export const CANCEL_ORDER_DETAIL = "[ORDER_MANAGEMENT] CANCEL_ORDER_DETAIL";
