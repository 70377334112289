export const GET_CATEGORY_LIST = "[CATEGORY_MANAGEMENT] GET_CATEGORIES";
export const GET_CATEGORY_LIST_SUCCESS =
  "[CATEGORY_MANAGEMENT] GET_CATEGORIES_SUCCESS";
export const GET_CATEGORY_LIST_FAILED =
  "[CATEGORY_MANAGEMENT] GET_CATEGORIES_FAILED";
export const CLEAR_CATEGORY_MANAGEMENT_STATE =
  "[CATEGORY_MANAGEMENT] CLEAR_CATEGORY_MANAGEMENT_STATE";

export const GET_BIG_CATEGORY_DETAIL =
  "[CATEGORY_MANAGEMENT] GET_BIG_CATEGORY_DETAIL";
export const GET_BIG_CATEGORY_DETAIL_SUCCESS =
  "[CATEGORY_MANAGEMENT] GET_BIG_CATEGORY_DETAIL_SUCCESS";
export const GET_BIG_CATEGORY_DETAIL_FAILED =
  "[CATEGORY_MANAGEMENT] GET_BIG_CATEGORY_DETAIL_FAILED";
export const CLEAR_BIG_CATEGORY_DETAIL =
  "[CATEGORY_MANAGEMENT] CLEAR_BIG_CATEGORY_DETAIL";

export const DELETE_BIG_CATEGORY = "[CATEGORY_MANAGEMENT] DELETE_BIG_CATEGORY";
export const DELETE_BIG_CATEGORY_SUCCESS =
  "[CATEGORY_MANAGEMENT] DELETE_BIG_CATEGORY_SUCCESS";
export const DELETE_BIG_CATEGORY_FAILED =
  "[CATEGORY_MANAGEMENT] DELETE_BIG_CATEGORY_FAILED";

export const ADD_BIG_CATEGORY = "[CATEGORY_MANAGEMENT] ADD_BIG_CATEGORY";
export const ADD_BIG_CATEGORY_SUCCESS =
  "[CATEGORY_MANAGEMENT] ADD_BIG_CATEGORY_SUCCESS";
export const ADD_BIG_CATEGORY_FAILED =
  "[CATEGORY_MANAGEMENT] ADD_BIG_CATEGORY_FAILED";

export const ADD_SMALL_CATEGORY = "[CATEGORY_MANAGEMENT] ADD_SMALL_CATEGORY";
export const ADD_SMALL_CATEGORY_SUCCESS =
  "[CATEGORY_MANAGEMENT] ADD_SMALL_CATEGORY_SUCCESS";
export const ADD_SMALL_CATEGORY_FAILED =
  "[CATEGORY_MANAGEMENT] ADD_SMALL_CATEGORY_FAILED";

export const DELETE_SMALL_CATEGORY =
  "[CATEGORY_MANAGEMENT] DELETE_SMALL_CATEGORY";
export const DELETE_SMALL_CATEGORY_SUCCESS =
  "[CATEGORY_MANAGEMENT] DELETE_SMALL_CATEGORY_SUCCESS";
export const DELETE_SMALL_CATEGORY_FAILED =
  "[CATEGORY_MANAGEMENT] DELETE_SMALL_CATEGORY_FAILED";

export const GET_SMALL_CATEGORY_DETAIL =
  "[CATEGORY_MANAGEMENT] GET_SMALL_CATEGORY_DETAIL";
export const GET_SMALL_CATEGORY_DETAIL_SUCCESS =
  "[CATEGORY_MANAGEMENT] GET_SMALL_CATEGORY_DETAIL_SUCCESS";
export const GET_SMALL_CATEGORY_DETAIL_FAILED =
  "[CATEGORY_MANAGEMENT] GET_SMALL_CATEGORY_DETAIL_FAILED";
export const CLEAR_SMALL_CATEGORY_DETAIL =
  "[CATEGORY_MANAGEMENT] CLEAR_SMALL_CATEGORY_DETAIL";

export const EDIT_BIG_CATEGORY = "[CATEGORY_MANAGEMENT] EDIT_BIG_CATEGORY";
export const EDIT_BIG_CATEGORY_SUCCESS =
  "[CATEGORY_MANAGEMENT] ADD_BIG_CATEGORY_SUCCESS";
export const EDIT_BIG_CATEGORY_FAILED =
  "[CATEGORY_MANAGEMENT] EDIT_BIG_CATEGORY_FAILED";

export const EDIT_SMALL_CATEGORY = "[CATEGORY_MANAGEMENT] EDIT_SMALL_CATEGORY";
export const EDIT_SMALL_CATEGORY_SUCCESS =
  "[CATEGORY_MANAGEMENT] EDIT_SMALL_CATEGORY_SUCCESS";
export const EDIT_SMALL_CATEGORY_FAILED =
  "[CATEGORY_MANAGEMENT] EDIT_SMALL_CATEGORY_FAILED";

export const UPDATE_CATERGORY_ORDER =
  "[CATEGORY_MANAGEMENT] UPDATE_CATERGORY_ORDER";
export const UPDATE_CATERGORY_ORDER_SUCCESS =
  "[CATEGORY_MANAGEMENT] UPDATE_CATERGORY_ORDER_SUCCESS";
export const UPDATE_CATERGORY_ORDER_FAILED =
  "[CATEGORY_MANAGEMENT] UPDATE_CATERGORY_ORDER_FAILED";

export const UPDATE_SMALL_CATERGORY_ORDER =
  "[CATEGORY_MANAGEMENT] UPDATE_SMALL_CATERGORY_ORDER";
export const UPDATE_SMALL_CATERGORY_ORDER_SUCCESS =
  "[CATEGORY_MANAGEMENT] UPDATE_SMALL_CATERGORY_ORDER_SUCCESS";
export const UPDATE_SMALL_CATERGORY_ORDER_FAILED =
  "[CATEGORY_MANAGEMENT] UPDATE_SMALL_CATERGORY_ORDER_FAILED";

export const GET_SYSTEM_LANGUAGES =
  "[CATEGORY_MANAGEMENT] GET_SYSTEM_LANGUAGES";
export const GET_SYSTEM_LANGUAGES_SUCCESS =
  "[CATEGORY_MANAGEMENT] GET_SYSTEM_LANGUAGES_SUCCESS";
export const GET_SYSTEM_LANGUAGES_FAILED =
  "[CATEGORY_MANAGEMENT] GET_SYSTEM_LANGUAGES_FAILED";

export const GET_CATEGORY_GALLERY =
  "[CATEGORY_MANAGEMENT] GET_CATEGORY_GALLERY";
export const GET_CATEGORY_GALLERY_SUCCESS =
  "[CATEGORY_MANAGEMENT] GET_CATEGORY_GALLERY_SUCCESS";
export const GET_CATEGORY_GALLERY_FAILED =
  "[CATEGORY_MANAGEMENT] GET_CATEGORY_GALLERY_FAILED";

export const DELETE_CATEGORY_GALLERY =
  "[CATEGORY_MANAGEMENT] DELETE_CATEGORY_GALLERY";
export const DELETE_CATEGORY_GALLERY_SUCCESS =
  "[CATEGORY_MANAGEMENT] DELETE_CATEGORY_GALLERY_SUCCESS";
export const DELETE_CATEGORY_GALLERY_FAILED =
  "[CATEGORY_MANAGEMENT] DELETE_CATEGORY_GALLERY_FAILED";

export const ADD_CATEGORY_GALLERY =
  "[CATEGORY_MANAGEMENT] ADD_CATEGORY_GALLERY";
export const ADD_CATEGORY_GALLERY_SUCCESS =
  "[CATEGORY_MANAGEMENT] ADD_CATEGORY_GALLERY_SUCCESS";
export const ADD_CATEGORY_GALLERY_FAILED =
  "[CATEGORY_MANAGEMENT] ADD_CATEGORY_GALLERY_FAILED";
