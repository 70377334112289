import { keyBy } from 'lodash';

import {
  GET_COUNTRIES,
  GET_COUNTRIES_FAILED,
  GET_COUNTRIES_SUCCESS,
} from "../actions/countryManagement.action";

const INITIAL_STATE = {
  countries: {
    data: {},
    total: 0,
    page: 1,
    search: '',
  },
  error: {},
  loading: false,
};

export default function countryReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COUNTRIES:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        countries: {
          ...payload,
          data: keyBy(payload.data, '_id'),
        },
      });
    case GET_COUNTRIES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
