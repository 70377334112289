import { keyBy } from 'lodash';

import {
  GET_TAGS,
  GET_TAGS_FAILED,
  GET_TAGS_SUCCESS,
  UPDATE_BLACKLIST_STATUS,
  UPDATE_BLACKLIST_STATUS_FAILED,
  UPDATE_BLACKLIST_STATUS_SUCCESS,
} from "../actions/tagManagement.action";

const INITIAL_STATE = {
  tags: {
    data: {},
    total: 0,
    page: 1,
    search: '',
    is_blacklist: ''
  },
  error: {},
  loading: false,
};

export default function tagReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TAGS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_TAGS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        tags: {
          ...payload,
          data: keyBy(payload.data, '_id'),
        },
      });
    case GET_TAGS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case UPDATE_BLACKLIST_STATUS:
      return Object.assign({}, state, {
        loading: true,
      });
    case UPDATE_BLACKLIST_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: {
          ...state.tags,
          data: {
            ...state.tags.data,
            [action.tagId]: {
              ...state.tags.data[action.tagId],
              ...payload
            }
          }
        }
      }
    case UPDATE_BLACKLIST_STATUS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
