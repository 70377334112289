import {
  GET_ACTIVE_USERS,
  GET_ACTIVE_USERS_FAILED,
  GET_ACTIVE_USERS_SUCCESS,

  GET_SUMMARY_USERS,
  GET_SUMMARY_USERS_FAILED,
  GET_SUMMARY_USERS_SUCCESS,

  GET_SUMMARY_JOBS,
  GET_SUMMARY_JOBS_FAILED,
  GET_SUMMARY_JOBS_SUCCESS,

  GET_JOBS_BY_STATUS,
  GET_JOBS_BY_STATUS_FAILED,
  GET_JOBS_BY_STATUS_SUCCESS,

  GET_SUMMARY_PRICE,
  GET_SUMMARY_PRICE_FAILED,
  GET_SUMMARY_PRICE_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS,
  GET_SUMMARY_PRICE_BY_STATUS_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS,
  GET_SUMMARY_REVENUE_BY_STATUS_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_SUCCESS,

  GET_DASHBOARD_SUMMARY_USER,
  GET_DASHBOARD_SUMMARY_USER_FAILED,
  GET_DASHBOARD_SUMMARY_USER_SUCCESS,

  GET_DASHBOARD_SUMMARY_GIG,
  GET_DASHBOARD_SUMMARY_GIG_FAILED,
  GET_DASHBOARD_SUMMARY_GIG_SUCCESS,

  GET_DASHBOARD_SUMMARY_TRANSACTION,
  GET_DASHBOARD_SUMMARY_TRANSACTION_FAILED,
  GET_DASHBOARD_SUMMARY_TRANSACTION_SUCCESS,

  GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED,
  GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_FAILED,
  GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_SUCCESS,

  GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG,
  GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_FAILED,
  GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_SUCCESS,

  GET_ACTIVE_USERS_DAILY,
  GET_ACTIVE_USERS_DAILY_FAILED,
  GET_ACTIVE_USERS_DAILY_SUCCESS,

  GET_ACTIVE_USERS_WEEKLY,
  GET_ACTIVE_USERS_WEEKLY_FAILED,
  GET_ACTIVE_USERS_WEEKLY_SUCCESS,

  GET_ACTIVE_USERS_MONTHLY,
  GET_ACTIVE_USERS_MONTHLY_SUCCESS,
  GET_ACTIVE_USERS_MONTHLY_FAILED,

  GET_JOBS_BY_STATUS_DAILY,
  GET_JOBS_BY_STATUS_DAILY_FAILED,
  GET_JOBS_BY_STATUS_DAILY_SUCCESS,

  GET_JOBS_BY_STATUS_WEEKLY,
  GET_JOBS_BY_STATUS_WEEKLY_FAILED,
  GET_JOBS_BY_STATUS_WEEKLY_SUCCESS,

  GET_JOBS_BY_STATUS_MONTHLY,
  GET_JOBS_BY_STATUS_MONTHLY_FAILED,
  GET_JOBS_BY_STATUS_MONTHLY_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS_DAILY,
  GET_SUMMARY_PRICE_BY_STATUS_DAILY_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_DAILY_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS_WEEKLY,
  GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS_MONTHLY,
  GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_HOURLY,
  GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_DAILY,
  GET_SUMMARY_REVENUE_BY_STATUS_DAILY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_DAILY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY,
  GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY,
  GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_SUCCESS,

  GET_ALL_SUMMARY_REVENUE,
  GET_ALL_SUMMARY_REVENUE_FAILED,
  GET_ALL_SUMMARY_REVENUE_SUCCESS,

} from "../actions/dashboard.action";

const INITIAL_STATE = {
  summaryUsers: {},
  summaryActiveUsers: {},
  summaryJobs: {},
  summaryJobsByStatus: {},
  summaryPrice: {},
  summaryPriceByStatus: {},
  summaryRevenueByStatus: {},
  dashboardSummaryUser: {},
  dashboardSummaryGig: {},
  dashboardSummaryTransaction: {},
  dashboardSummaryTransactionClosed: {},
  dashboardSummarySellerCreatedGig: {},
  summaryActiveUsersDaily: {},
  summaryActiveUsersWeekly: {},
  summaryActiveUsersMonthly: {},
  summaryJobsByStatusDaily: {},
  summaryJobsByStatusWeekly: {},
  summaryJobsByStatusMonthly: {},
  summaryPriceByStatusDaily: {},
  summaryPriceByStatusWeekly: {},
  summaryPriceByStatusMonthly: {},
  summaryRevenueByStatusHourly: {},
  summaryRevenueByStatusDaily: {},
  summaryRevenueByStatusWeekly: {},
  summaryRevenueByStatusMonthly: {},
  allSummaryRevenue: {},
  error: {},
  loading: false,
};

export default function dashboardReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SUMMARY_USERS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryUsers: {
          ...payload,
        },
      });
    case GET_SUMMARY_USERS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_ACTIVE_USERS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ACTIVE_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryActiveUsers: {
          ...payload,
        },
      });
    case GET_ACTIVE_USERS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_SUMMARY_JOBS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_JOBS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryJobs: {
          ...payload,
        },
      });
    case GET_SUMMARY_JOBS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_JOBS_BY_STATUS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_JOBS_BY_STATUS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryJobsByStatus: {
          ...payload,
        },
      });
    case GET_JOBS_BY_STATUS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_SUMMARY_PRICE:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_PRICE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryPrice: {
          ...payload,
        },
      });
    case GET_SUMMARY_PRICE_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_SUMMARY_PRICE_BY_STATUS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_PRICE_BY_STATUS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryPriceByStatus: {
          ...payload,
        },
      });
    case GET_SUMMARY_PRICE_BY_STATUS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_SUMMARY_REVENUE_BY_STATUS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryRevenueByStatus: {
          ...payload,
        },
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_DASHBOARD_SUMMARY_USER:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_DASHBOARD_SUMMARY_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        dashboardSummaryUser: {
          ...payload,
        },
      });
    case GET_DASHBOARD_SUMMARY_USER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_DASHBOARD_SUMMARY_GIG:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_DASHBOARD_SUMMARY_GIG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        dashboardSummaryGig: {
          ...payload,
        },
      });
    case GET_DASHBOARD_SUMMARY_GIG_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_DASHBOARD_SUMMARY_TRANSACTION:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_DASHBOARD_SUMMARY_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        dashboardSummaryTransaction: {
          ...payload,
        },
      });
    case GET_DASHBOARD_SUMMARY_TRANSACTION_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        dashboardSummaryTransactionClosed: {
          ...payload,
        },
      });
    case GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        dashboardSummarySellerCreatedGig: {
          ...payload,
        },
      });
    case GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_ACTIVE_USERS_DAILY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ACTIVE_USERS_DAILY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryActiveUsersDaily: {
          ...payload,
        },
      });
    case GET_ACTIVE_USERS_DAILY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_ACTIVE_USERS_WEEKLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ACTIVE_USERS_WEEKLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryActiveUsersWeekly: {
          ...payload,
        },
      });
    case GET_ACTIVE_USERS_WEEKLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_ACTIVE_USERS_MONTHLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ACTIVE_USERS_MONTHLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryActiveUsersMonthly: {
          ...payload,
        },
      });
    case GET_ACTIVE_USERS_MONTHLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_JOBS_BY_STATUS_DAILY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_JOBS_BY_STATUS_DAILY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryJobsByStatusDaily: {
          ...payload,
        },
      });
    case GET_JOBS_BY_STATUS_DAILY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_JOBS_BY_STATUS_WEEKLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_JOBS_BY_STATUS_WEEKLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryJobsByStatusWeekly: {
          ...payload,
        },
      });
    case GET_JOBS_BY_STATUS_WEEKLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_JOBS_BY_STATUS_MONTHLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_JOBS_BY_STATUS_MONTHLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryJobsByStatusMonthly: {
          ...payload,
        },
      });
    case GET_JOBS_BY_STATUS_MONTHLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_SUMMARY_PRICE_BY_STATUS_DAILY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_PRICE_BY_STATUS_DAILY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryPriceByStatusDaily: {
          ...payload,
        },
      });
    case GET_SUMMARY_PRICE_BY_STATUS_DAILY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_SUMMARY_PRICE_BY_STATUS_WEEKLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryPriceByStatusWeekly: {
          ...payload,
        },
      });
    case GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_SUMMARY_PRICE_BY_STATUS_MONTHLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryPriceByStatusMonthly: {
          ...payload,
        },
      });
    case GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case GET_SUMMARY_REVENUE_BY_STATUS_HOURLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryRevenueByStatusHourly: {
          ...payload,
        },
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_DAILY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_DAILY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryRevenueByStatusDaily: {
          ...payload,
        },
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_DAILY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryRevenueByStatusWeekly: {
          ...payload,
        },
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        summaryRevenueByStatusMonthly: {
          ...payload,
        },
      });
    case GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_ALL_SUMMARY_REVENUE:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ALL_SUMMARY_REVENUE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        allSummaryRevenue: {
          ...payload,
        },
      });
    case GET_ALL_SUMMARY_REVENUE_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });




    default:
      return state;
  }
}
