export const GET_CURRENCIES = "[CURRENCY] GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "[CURRENCY] GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILED = "[CURRENCY] GET_CURRENCIES_FAILED";

export const CREATE_CURRENCY = "[CURRENCY] CREATE_CURRENCY";
export const CREATE_CURRENCY_SUCCESS = "[CURRENCY] CREATE_CURRENCY_SUCCESS";
export const CREATE_CURRENCY_FAILED = "[CURRENCY] CREATE_CURRENCY_FAILED";

export const UPDATE_CURRENCY = "[CURRENCY] UPDATE_CURRENCY";
export const UPDATE_CURRENCY_SUCCESS = "[CURRENCY] UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_FAILED = "[CURRENCY] UPDATE_CURRENCY_FAILED";

export const DELETE_CURRENCY = "[CURRENCY] DELETE_CURRENCY";
export const DELETE_CURRENCY_SUCCESS = "[CURRENCY] DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAILED = "[CURRENCY] DELETE_CURRENCY_FAILED";
