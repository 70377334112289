export const GET_CITIES = "[CITY] GET_CITIES";
export const GET_CITIES_SUCCESS = "[CITY] GET_CITIES_SUCCESS";
export const GET_CITIES_FAILED = "[CITY] GET_CITIES_FAILED";

export const CREATE_CITY = "[CITY] CREATE_CITY";
export const CREATE_CITY_SUCCESS = "[CITY] CREATE_CITY_SUCCESS";
export const CREATE_CITY_FAILED = "[CITY] CREATE_CITY_FAILED";

export const UPDATE_CITY = "[CITY] UPDATE_CITY";
export const UPDATE_CITY_SUCCESS = "[CITY] UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAILED = "[CITY] UPDATE_CITY_FAILED";

export const DELETE_CITY = "[CITY] DELETE_CITY";
export const DELETE_CITY_SUCCESS = "[CITY] DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAILED = "[CITY] DELETE_CITY_FAILED";
