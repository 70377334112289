import { keyBy } from 'lodash';

import {
  GET_LEVELS,
  GET_LEVELS_FAILED,
  GET_LEVELS_SUCCESS,
} from "../actions/levelManagement.action";

const INITIAL_STATE = {
  levels: {
    data: {},
    total: 0,
    page: 1,
    search: '',
  },
  error: {},
  loading: false,
};

export default function levelReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LEVELS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_LEVELS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        levels: {
          ...payload,
          data: keyBy(payload.data, '_id'),
        },
      });
    case GET_LEVELS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
