import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  CLEAR_USER_MANAGEMENT_STATE,
  SEND_EMAIL_USER,
  SEND_EMAIL_USER_SUCCESS,
  SEND_EMAIL_USER_FAILED,
} from "../actions/userManagement.action";

const INITIAL_STATE = {
  userList: {
    data: [],
    total: 0,
    status: "",
    page: 1,
    search: "",
    role: null,
    limit: 20,
  },
  error: {},
  loading: false,
};

export default function userManagementReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_LIST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_USER_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        userList: payload,
      });
    case GET_USER_LIST_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case CLEAR_USER_MANAGEMENT_STATE: {
      return INITIAL_STATE;
    }
    case SEND_EMAIL_USER:
      return Object.assign({}, state, {
        loading: true,
      });
    case SEND_EMAIL_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case SEND_EMAIL_USER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
