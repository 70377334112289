import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
} from "../actions/user.action";

const initialState = {
  loading: false,
  error: {},
  failed: false,
  accessToken: "",
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN:
      return Object.assign({}, state, {
        loading: true,
      });
    case USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loading: true,
        accessToken: payload.message.accessToken,
      });
    case USER_LOGIN_FAILED:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
}
