import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_GIG_LIST,
  GET_GIG_LIST_SUCCESS,
  GET_GIG_LIST_FAILED,
  APPROVE_GIG,
  APPROVE_GIG_FAILED,
  DENY_GIG,
  DENY_GIG_FAILED,
  GET_GIG_DETAIL,
  GET_GIG_DETAIL_SUCCESS,
  GET_GIG_DETAIL_FAILED,
  UPDATE_BLACKLIST_STATUS,
  UPDATE_BLACKLIST_STATUS_FAILED,
} from "../actions/gigManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";
import { toast } from "react-toastify";

async function getGigListFromApi(params) {
  const url = `${apiUrl}/gigs?${new URLSearchParams(params)}`;
  return requestApi(url, "GET");
}

function* getGigList(action) {
  try {
    const result = yield call(() => getGigListFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_GIG_LIST_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_GIG_LIST_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_GIG_LIST_FAILED, payload: e });
  }
}

const approveGigFromApi = async (gigId) => {
  const url = `${apiUrl}/gigs/${gigId}/active`;
  return requestApi(url, "PUT");
};

function* approveGig(action) {
  try {
    const result = yield call(() => approveGigFromApi(action.payload.gigId));
    if (result.status === 200) {
      toast.success("Approve gig success!");
      yield put({
        type: GET_GIG_LIST,
        payload: action.payload.params,
      });
    } else {
      // yield put({ type: GET_USER_LIST_FAILED, payload: result.error });
      toast.error("Approve gig failed!");
    }
  } catch (e) {
    yield put({ type: APPROVE_GIG_FAILED, payload: e });
  }
}

const denyGigFromApi = async (gigId, data) => {
  const url = `${apiUrl}/gigs/${gigId}/deny`;
  return requestApi(url, "PUT", JSON.stringify(data));
};

function* denyGig(action) {
  try {
    const result = yield call(() => denyGigFromApi(action.payload.gigId, { reason: action.payload.reason || '' }));
    if (result.status === 200) {
      toast.success("Deny gig success!");
      yield put({
        type: GET_GIG_LIST,
        payload: action.payload.params,
      });
    } else {
      // yield put({ type: GET_USER_LIST_FAILED, payload: result.error });
      toast.error("Deny gig failed!");
    }
  } catch (e) {
    yield put({ type: DENY_GIG_FAILED, payload: e });
  }
}

async function getGigDetailFromApi(gigId) {
  const url = `${apiUrl}/gigs/${gigId}`;
  return requestApi(url, "GET");
}

function* getGigDetail(action) {
  try {
    const result = yield call(() => getGigDetailFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_GIG_DETAIL_SUCCESS,
        payload: result.body,
      });
    } else {
      yield put({ type: GET_GIG_DETAIL_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_GIG_DETAIL_FAILED, payload: e });
  }
}

async function updateBlacklistStatusFromApi(gigId, data) {
  const url = `${apiUrl}/gigs/${gigId}/blacklist`;
  return requestApi(url, "PUT", JSON.stringify(data));
}

function* updateBlacklistStatus(action) {
  try {
    const { gigId, data, params } = action.payload;
    const result = yield call(() => updateBlacklistStatusFromApi(gigId, data));
    if (result.status === 200) {
      yield put({
        type: GET_GIG_LIST,
        payload: params,
      });
    } else {
      yield put({ type: UPDATE_BLACKLIST_STATUS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: UPDATE_BLACKLIST_STATUS_FAILED, payload: e });
  }
}

export default function* gigManagementSaga() {
  yield all([
    takeLatest(GET_GIG_LIST, getGigList),
    takeLatest(APPROVE_GIG, approveGig),
    takeLatest(DENY_GIG, denyGig),
    takeLatest(GET_GIG_DETAIL, getGigDetail),
    takeLatest(UPDATE_BLACKLIST_STATUS, updateBlacklistStatus),
  ]);
}
