import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_RATE_OPTIONS,
  GET_RATE_OPTIONS_FAILED,
  GET_RATE_OPTIONS_SUCCESS,
  CREATE_RATE_OPTION,
  CREATE_RATE_OPTION_FAILED,
  UPDATE_RATE_OPTION,
  UPDATE_RATE_OPTION_FAILED,
  DELETE_RATE_OPTION,
  DELETE_RATE_OPTION_FAILED,
} from "../actions/rateOptionManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getRateOptionsFromApi = async (query) => {
  const url = `${apiUrl}/rate-options?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getRateOptions(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getRateOptionsFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_RATE_OPTIONS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_RATE_OPTIONS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_RATE_OPTIONS_FAILED, payload: e });
  }
}


const createRateOptionFromApi = async (data) => {
  const url = `${apiUrl}/rate-options`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createRateOption(action) {
  try {
    const result = yield call(() => createRateOptionFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_RATE_OPTIONS,
      });
      toast.success("Create rate option success");
    } else {
      yield put({ type: CREATE_RATE_OPTION_FAILED, payload: result.error });
      toast.error("Create rate option failed");
    }
  } catch (e) {
    yield put({ type: CREATE_RATE_OPTION_FAILED, payload: e });
    toast.error("Create rate option failed");
  }
}

const updateRateOptionFromApi = async (id, data) => {
  const url = `${apiUrl}/rate-options/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updateRateOption(action) {
  try {
    const { payload, rateOptionId } = action;
    const result = yield call(() => updateRateOptionFromApi(rateOptionId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_RATE_OPTIONS,
      });
      toast.success("Update rate option success");
    } else {
      yield put({ type: UPDATE_RATE_OPTION_FAILED, payload: result.error });
      toast.error("Update rate option failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_RATE_OPTION_FAILED, payload: e });
    toast.error("Update rate option failed");
  }
}

const deleteRateOptionFromApi = async (id) => {
  const url = `${apiUrl}/rate-options/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteRateOption(action) {
  try {
    const { payload, rateOptionId } = action;
    const result = yield call(() => deleteRateOptionFromApi(rateOptionId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_RATE_OPTIONS,
      });
      toast.success("Delete rate option success");
    } else {
      yield put({ type: DELETE_RATE_OPTION_FAILED, payload: result.error });
      toast.error("Delete rate option failed");
    }
  } catch (e) {
    yield put({ type: DELETE_RATE_OPTION_FAILED, payload: e });
    toast.error("Delete rate option failed");
  }
}

export default function* rateOptionsSaga() {
  yield all([
    takeLatest(GET_RATE_OPTIONS, getRateOptions),
    takeLatest(CREATE_RATE_OPTION, createRateOption),
    takeLatest(UPDATE_RATE_OPTION, updateRateOption),
    takeLatest(DELETE_RATE_OPTION, deleteRateOption),
  ]);
}
