export const GET_GIG_LIST = "[GIG_MANAGEMENT] GET_GIGS";
export const GET_GIG_LIST_SUCCESS = "[GIG_MANAGEMENT] GET_GIGS_SUCCESS";
export const GET_GIG_LIST_FAILED = "[GIG_MANAGEMENT] GET_GIGS_FAILED";
export const CLEAR_GIG_MANAGEMENT_STATE =
  "[GIG_MANAGEMENT] CLEAR_GIG_MANAGEMENT_STATE";

export const APPROVE_GIG = "[GIG_MANAGEMENT] APPROVE_GIG";
export const APPROVE_GIG_FAILED = "[GIG_MANAGEMENT] APPROVE_GIG_FAILED";

export const DENY_GIG = "[GIG_MANAGEMENT] DENY_GIG";
export const DENY_GIG_FAILED = "[GIG_MANAGEMENT] DENY_GIG_FAILED";

export const GET_GIG_DETAIL = "[GIG_MANAGEMENT] GET_GIG_DETAIL";
export const GET_GIG_DETAIL_SUCCESS = "[GIG_MANAGEMENT] GET_GIG_DETAIL_SUCCESS";
export const GET_GIG_DETAIL_FAILED = "[GIG_MANAGEMENT] GET_GIG_DETAIL_FAILED";
export const CANCEL_GIG_DETAIL = "[GIG_MANAGEMENT] CANCEL_GIG_DETAIL";

export const UPDATE_BLACKLIST_STATUS = "[GIG_MANAGEMENT] UPDATE_BLACKLIST_STATUS";
export const UPDATE_BLACKLIST_STATUS_SUCCESS = "[GIG_MANAGEMENT] UPDATE_BLACKLIST_STATUS_SUCCESS";
export const UPDATE_BLACKLIST_STATUS_FAILED = "[GIG_MANAGEMENT] UPDATE_BLACKLIST_STATUS_FAILED";
