import {
  GET_NEW_REGISTER,
  GET_NEW_REGISTER_SUCCESS,
  GET_NEW_REGISTER_FAILED,
} from "../actions/newRegisterManagement.action";

const initialState = {
  loading: false,
  error: {},
  failed: false,
  newRegisters: []
};

export default function newRegisterReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_NEW_REGISTER:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_NEW_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        newRegisters: payload,
      });
    case GET_NEW_REGISTER_FAILED:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
}
