import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_LEVELS,
  GET_LEVELS_FAILED,
  GET_LEVELS_SUCCESS,
  CREATE_LEVEL,
  CREATE_LEVEL_FAILED,
  UPDATE_LEVEL,
  UPDATE_LEVEL_FAILED,
  DELETE_LEVEL,
  DELETE_LEVEL_FAILED,
} from "../actions/levelManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getLevelsFromApi = async (query) => {
  const url = `${apiUrl}/levels?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getLevels(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getLevelsFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_LEVELS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_LEVELS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_LEVELS_FAILED, payload: e });
  }
}


const createLevelFromApi = async (data) => {
  const url = `${apiUrl}/levels`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createLevel(action) {
  try {
    const result = yield call(() => createLevelFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_LEVELS,
      });
      toast.success("Create level success");
    } else {
      yield put({ type: CREATE_LEVEL_FAILED, payload: result.error });
      toast.error("Create level failed");
    }
  } catch (e) {
    yield put({ type: CREATE_LEVEL_FAILED, payload: e });
    toast.error("Create level failed");
  }
}

const updateLevelFromApi = async (id, data) => {
  const url = `${apiUrl}/levels/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updateLevel(action) {
  try {
    const { payload, levelId } = action;
    const result = yield call(() => updateLevelFromApi(levelId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_LEVELS,
      });
      toast.success("Update level success");
    } else {
      yield put({ type: UPDATE_LEVEL_FAILED, payload: result.error });
      toast.error("Update level failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_LEVEL_FAILED, payload: e });
    toast.error("Update level failed");
  }
}

const deleteLevelFromApi = async (id) => {
  const url = `${apiUrl}/levels/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteLevel(action) {
  try {
    const { payload, levelId } = action;
    const result = yield call(() => deleteLevelFromApi(levelId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_LEVELS,
      });
      toast.success("Delete level success");
    } else {
      yield put({ type: DELETE_LEVEL_FAILED, payload: result.error });
      toast.error("Delete level failed");
    }
  } catch (e) {
    yield put({ type: DELETE_LEVEL_FAILED, payload: e });
    toast.error("Delete level failed");
  }
}

export default function* levelsSaga() {
  yield all([
    takeLatest(GET_LEVELS, getLevels),
    takeLatest(CREATE_LEVEL, createLevel),
    takeLatest(UPDATE_LEVEL, updateLevel),
    takeLatest(DELETE_LEVEL, deleteLevel),
  ]);
}
