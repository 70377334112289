export const GET_UI = "[UI] GET_UI";
export const GET_UI_SUCCESS = "[UI] GET_UI_SUCCESS";
export const GET_UI_FAILED = "[UI] GET_UI_FAILED";

export const CREATE_UI = "[UI] CREATE_UI";
export const CREATE_UI_SUCCESS = "[UI] CREATE_UI_SUCCESS";
export const CREATE_UI_FAILED = "[UI] CREATE_UI_FAILED";

export const UPDATE_UI = "[UI] UPDATE_UI";
export const UPDATE_UI_SUCCESS = "[UI] UPDATE_UI_SUCCESS";
export const UPDATE_UI_FAILED = "[UI] UPDATE_UI_FAILED";

export const DELETE_UI = "[UI] DELETE_UI";
export const DELETE_UI_SUCCESS = "[UI] DELETE_UI_SUCCESS";
export const DELETE_UI_FAILED = "[UI] DELETE_UI_FAILED";