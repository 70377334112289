export const GET_TAGS = "[TAG] GET_TAGS";
export const GET_TAGS_SUCCESS = "[TAG] GET_TAGS_SUCCESS";
export const GET_TAGS_FAILED = "[TAG] GET_TAGS_FAILED";

export const CREATE_TAG = "[TAG] CREATE_TAG";
export const CREATE_TAG_SUCCESS = "[TAG] CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILED = "[TAG] CREATE_TAG_FAILED";

export const UPDATE_TAG = "[TAG] UPDATE_TAG";
export const UPDATE_TAG_SUCCESS = "[TAG] UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAILED = "[TAG] UPDATE_TAG_FAILED";

export const DELETE_TAG = "[TAG] DELETE_TAG";
export const DELETE_TAG_SUCCESS = "[TAG] DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILED = "[TAG] DELETE_TAG_FAILED";

export const UPDATE_BLACKLIST_STATUS = "[TAG] UPDATE_BLACKLIST_STATUS";
export const UPDATE_BLACKLIST_STATUS_SUCCESS = "[TAG] UPDATE_BLACKLIST_STATUS_SUCCESS";
export const UPDATE_BLACKLIST_STATUS_FAILED = "[TAG] UPDATE_BLACKLIST_STATUS_FAILED";
