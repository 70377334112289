import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_OFFER_LIST,
  GET_OFFER_LIST_SUCCESS,
  GET_OFFER_LIST_FAILED,
  APPROVE_OFFER,
  APPROVE_OFFER_FAILED,
  DENY_OFFER,
  DENY_OFFER_FAILED,
  GET_OFFER_DETAIL_SUCCESS,
  GET_OFFER_DETAIL_FAILED,
  GET_OFFER_DETAIL,
} from "./../actions/offerManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";
import { toast } from "react-toastify";

async function getOrderListFromApi(params) {
  const url = `${apiUrl}/requests?${new URLSearchParams(params)}`;
  return requestApi(url, "GET");
}

function* getUserList(action) {
  try {
    const result = yield call(() => getOrderListFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_OFFER_LIST_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_OFFER_LIST_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_OFFER_LIST_FAILED, payload: e });
  }
}

const approveOfferFromApi = async (offerId) => {
  const url = `${apiUrl}/requests/${offerId}/active`;
  return requestApi(url, "PUT");
};

function* approveOffer(action) {
  try {
    const result = yield call(() =>
      approveOfferFromApi(action.payload.offerId)
    );
    if (result.status === 200) {
      toast.success("Approve offer success!");
      yield put({
        type: GET_OFFER_LIST,
        payload: action.payload.params,
      });
    } else {
      // yield put({ type: GET_USER_LIST_FAILED, payload: result.error });
      toast.error("Approve gig failed!");
    }
  } catch (e) {
    yield put({ type: APPROVE_OFFER_FAILED, payload: e });
  }
}

const denyOfferFromApi = async (offerId) => {
  const url = `${apiUrl}/requests/${offerId}/deny`;
  return requestApi(url, "PUT");
};

function* denyOffer(action) {
  try {
    const result = yield call(() => denyOfferFromApi(action.payload.offerId));
    if (result.status === 200) {
      toast.success("Deny offer success!");
      yield put({
        type: GET_OFFER_LIST,
        payload: action.payload.params,
      });
    } else {
      // yield put({ type: GET_USER_LIST_FAILED, payload: result.error });
      toast.error("Deny offer failed!");
    }
  } catch (e) {
    yield put({ type: DENY_OFFER_FAILED, payload: e });
  }
}

async function getOfferDetailFromApi(offerId) {
  const url = `${apiUrl}/requests/${offerId}`;
  return requestApi(url, "GET");
}

function* getOfferDetail(action) {
  try {
    const result = yield call(() => getOfferDetailFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_OFFER_DETAIL_SUCCESS,
        payload: result.body,
      });
    } else {
      yield put({ type: GET_OFFER_DETAIL_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_OFFER_DETAIL_FAILED, payload: e });
  }
}

export default function* userManagementSaga() {
  yield all([
    takeLatest(GET_OFFER_LIST, getUserList),
    takeLatest(APPROVE_OFFER, approveOffer),
    takeLatest(DENY_OFFER, denyOffer),
    takeLatest(GET_OFFER_DETAIL, getOfferDetail),
  ]);
}
