import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_KEYWORDS,
  GET_KEYWORDS_SUCCESS,
  GET_KEYWORDS_FAILED,
  CREATE_KEYWORDS,
  CREATE_KEYWORDS_FAILED,
  UPDATE_KEYWORDS,
  UPDATE_KEYWORDS_FAILED,
  DELETE_KEYWORDS,
  DELETE_KEYWORDS_FAILED,
  UPDATE_CACHE_KEYWORDS,
  UPDATE_CACHE_KEYWORDS_FAILED,
  UPDATE_CACHE_GIGS,
  UPDATE_CACHE_GIGS_FAILED,
  UPDATE_CACHE_USERNAME,
  UPDATE_CACHE_USERNAME_FAILED,
  IMPORT_KEYWORD,
  IMPORT_KEYWORD_FAILED, IMPORT_KEYWORD_SUCCESS
} from "../actions/keyword.action";
import {apiRequestFilePost, requestApi} from "../utils/requestHelper";
import { apiUrl } from "../config/config";
import { toast } from "react-toastify";
import { get } from "lodash";

const getKeywordsFromApi = async () => {
  const url = `${apiUrl}/keywords`;
  return requestApi(url, "GET");
};

function* getKeywords(action) {
  try {
    const result = yield call(() => getKeywordsFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_KEYWORDS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_KEYWORDS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_KEYWORDS_FAILED, payload: e });
  }
}

const createKeywordFromApi = async (data) => {
  const url = `${apiUrl}/keywords`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createCreateKeyWord(action) {
  try {
    const result = yield call(() => createKeywordFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_KEYWORDS,
      });
      toast.success("Create keyword success");
    } else {
      yield put({ type: CREATE_KEYWORDS_FAILED, payload: result.error });
      toast.error("Create keyword failed");
    }
  } catch (e) {
    yield put({ type: CREATE_KEYWORDS_FAILED, payload: e });
    toast.error("Create keyword failed");
  }
}

const updateKeywordFromApi = async (data, keywordId) => {
  const url = `${apiUrl}/keywords/${keywordId}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updateKeyword(action) {
  try {
    const { payload, keywordId } = action;
    const result = yield call(() => updateKeywordFromApi(payload, keywordId));
    if (result.status === 200) {
      yield put({
        type: GET_KEYWORDS,
      });
      toast.success("Update keyword success");
    } else {
      yield put({ type: UPDATE_KEYWORDS_FAILED, payload: result.error });
      toast.error("Update keyword failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_KEYWORDS_FAILED, payload: e });
    toast.error("Update keyword failed");
  }
}

const deleteKeywordFromApi = async (keywordId) => {
  const url = `${apiUrl}/keywords/${keywordId}`;
  return requestApi(url, 'DELETE');
};

function* deleteKeyword(action) {
  try {
    const { keywordId } = action;
    const result = yield call(() => deleteKeywordFromApi(keywordId));
    if (result.status === 200) {
      yield put({
        type: GET_KEYWORDS,
      });
      toast.success("Delete keyword success");
    } else {
      yield put({ type: DELETE_KEYWORDS_FAILED, payload: result.error });
      toast.error("Delete keyword failed");
    }
  } catch (e) {
    yield put({ type: DELETE_KEYWORDS_FAILED, payload: e });
    toast.error("Delete keyword failed");
  }
}

const updateCacheKeywordsFromApi = async () => {
  const url = `${apiUrl}/elasticsearch/keywords`;
  return requestApi(url, 'PUT');
};

function* updateCacheKeywords(action) {
  try {
    const result = yield call(() => updateCacheKeywordsFromApi());
    if (result.status === 200) {
      yield put({
        type: GET_KEYWORDS,
      });
      toast.success("Update cache success");
    } else {
      yield put({ type: UPDATE_CACHE_KEYWORDS_FAILED, payload: result.error });
      toast.error("Update cache failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_CACHE_KEYWORDS_FAILED, payload: e });
    toast.error("Update cache failed");
  }
}

const updateCacheGigsFromApi = async () => {
  const url = `${apiUrl}/elasticsearch/gigs`;
  return requestApi(url, 'PUT');
};

function* updateCacheGigs(action) {
  try {
    const result = yield call(() => updateCacheGigsFromApi());
    if (result.status === 200) {
      yield put({
        type: GET_KEYWORDS,
      });
      toast.success("Update cache success");
    } else {
      yield put({ type: UPDATE_CACHE_GIGS_FAILED, payload: result.error });
      toast.error("Update cache failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_CACHE_GIGS_FAILED, payload: e });
    toast.error("Update cache failed");
  }
}

const updateCacheUsernamesFromApi = async () => {
  const url = `${apiUrl}/elasticsearch/usernames`;
  return requestApi(url, 'PUT');
};

function* updateCacheUsernames(action) {
  try {
    const result = yield call(() => updateCacheUsernamesFromApi());
    if (result.status === 200) {
      yield put({
        type: GET_KEYWORDS,
      });
      toast.success("Update cache success");
    } else {
      yield put({ type: UPDATE_CACHE_USERNAME_FAILED, payload: result.error });
      toast.error("Update cache failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_CACHE_USERNAME_FAILED, payload: e });
    toast.error("Update cache failed");
  }
}

const importKeywordFromApi = async (data) => {
  const url = `${apiUrl}/keywords/imports`;

  return apiRequestFilePost(url, { file: get(data, 'file.file') }, null, "PUT");
};

function* importKeyword(action) {
  try {
    const { payload } = action;
    const result = yield call(() => importKeywordFromApi(payload));
    if (result.status === 200) {
      yield put({ type: IMPORT_KEYWORD_SUCCESS });
      toast.success("Import keyword success");
    } else {
      yield put({ type: IMPORT_KEYWORD_FAILED, payload: result.error });
      toast.error("Import keyword failed");
    }
  } catch (e) {
    yield put({ type: IMPORT_KEYWORD_FAILED, payload: e });
    toast.error("Import keyword failed");
  }
}


export default function* keywordSaga() {
  yield all([
    takeLatest(GET_KEYWORDS, getKeywords),
    takeLatest(CREATE_KEYWORDS, createCreateKeyWord),
    takeLatest(UPDATE_KEYWORDS, updateKeyword),
    takeLatest(DELETE_KEYWORDS, deleteKeyword),
    takeLatest(UPDATE_CACHE_KEYWORDS, updateCacheKeywords),
    takeLatest(UPDATE_CACHE_GIGS, updateCacheGigs),
    takeLatest(UPDATE_CACHE_USERNAME, updateCacheUsernames),
    takeLatest(IMPORT_KEYWORD, importKeyword),
  ]);
}
