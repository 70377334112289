import { keyBy } from 'lodash';

import {
  GET_LANGUAGES_SYSTEM,
  GET_LANGUAGES_SYSTEM_FAILED,
  GET_LANGUAGES_SYSTEM_SUCCESS,
} from "../actions/languageSystemManagement.action";

const INITIAL_STATE = {
  languagesSystem: {
    data: {},
    total: 0,
    page: 1,
    search: '',
  },
  error: {},
  loading: false,
};

export default function languageSystemReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LANGUAGES_SYSTEM:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_LANGUAGES_SYSTEM_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        languagesSystem: {
          ...payload,
          data: keyBy(payload.data, '_id'),
        },
      });
    case GET_LANGUAGES_SYSTEM_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
