import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILED,
  CREATE_LANGUAGE,
  CREATE_LANGUAGE_FAILED,
  UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_FAILED,
  DELETE_LANGUAGE,
  DELETE_LANGUAGE_FAILED,
} from "../actions/languageManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getLanguagesFromApi = async (query) => {
  const url = `${apiUrl}/languages?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getLanguages(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getLanguagesFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_LANGUAGES_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_LANGUAGES_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_LANGUAGES_FAILED, payload: e });
  }
}


const createLanguageFromApi = async (data) => {
  const url = `${apiUrl}/languages`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createLanguage(action) {
  try {
    const result = yield call(() => createLanguageFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_LANGUAGES,
      });
      toast.success("Create language success");
    } else {
      yield put({ type: CREATE_LANGUAGE_FAILED, payload: result.error });
      toast.error("Create language failed");
    }
  } catch (e) {
    yield put({ type: CREATE_LANGUAGE_FAILED, payload: e });
    toast.error("Create language failed");
  }
}

const updateLanguageFromApi = async (id, data) => {
  const url = `${apiUrl}/languages/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updateLanguage(action) {
  try {
    const { payload, languageId } = action;
    const result = yield call(() => updateLanguageFromApi(languageId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_LANGUAGES,
      });
      toast.success("Update language success");
    } else {
      yield put({ type: UPDATE_LANGUAGE_FAILED, payload: result.error });
      toast.error("Update language failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_LANGUAGE_FAILED, payload: e });
    toast.error("Update language failed");
  }
}

const deleteLanguageFromApi = async (id) => {
  const url = `${apiUrl}/languages/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteLanguage(action) {
  try {
    const { payload, languageId } = action;
    const result = yield call(() => deleteLanguageFromApi(languageId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_LANGUAGES,
      });
      toast.success("Delete language success");
    } else {
      yield put({ type: DELETE_LANGUAGE_FAILED, payload: result.error });
      toast.error("Delete language failed");
    }
  } catch (e) {
    yield put({ type: DELETE_LANGUAGE_FAILED, payload: e });
    toast.error("Delete language failed");
  }
}

export default function* languagesSaga() {
  yield all([
    takeLatest(GET_LANGUAGES, getLanguages),
    takeLatest(CREATE_LANGUAGE, createLanguage),
    takeLatest(UPDATE_LANGUAGE, updateLanguage),
    takeLatest(DELETE_LANGUAGE, deleteLanguage),
  ]);
}
