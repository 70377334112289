export function returnResult(url, options) {
  return fetch(url, options)
    .then(function handleErrors(res) {
      return res.json().then((json) => {
        if (json.status === 401 || json.status === 403) {
          window.location.href = "/login";
          localStorage.setItem("jwt", "");
        }
        return json;
      });
    })
    .catch((error) => {
      return { error: error };
    });
}

export function requestApi(
  url,
  method = "GET",
  body,
  contentType = "application/json"
) {
  try {
    const accessToken = localStorage.getItem("jwt");
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", contentType);
    headers.append("Authorization", `Bearer ${accessToken}`);
    headers.append("Credentials", true);
    if (method === "GET") {
      return returnResult(url, {
        method,
        headers,
      });
    }
    return returnResult(url, {
      method,
      headers,
      body,
    });
  } catch (e) {
    console.log(e);
    return e;
  }
}

export function apiRequestFilePost(url, files, body, method = "POST") {
  try {
    const formData = new FormData();
    const headers = new Headers();
    const accessToken = localStorage.getItem("jwt");
    headers.append("Authorization", `Bearer ${accessToken}`);
    for (let key in files) {
      const target = Array.isArray(files[key]) ? files[key] : [files[key]];
      target.map((f) => {
        if (f) {
          formData.append(key, f, f.name);
        }
      });
    }
    for (let key in body) {
      formData.append(key, body[key]);
    }
    const options = {
      method,
      headers,
      body: formData,
    };
    return returnResult(url, options);
  } catch (e) {
    console.log(e);
    return e;
  }
}
