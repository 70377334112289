import {
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAILED,
  CLEAR_CATEGORY_MANAGEMENT_STATE,
  GET_BIG_CATEGORY_DETAIL_SUCCESS,
  GET_BIG_CATEGORY_DETAIL_FAILED,
  GET_BIG_CATEGORY_DETAIL,
  GET_SMALL_CATEGORY_DETAIL_SUCCESS,
  GET_SMALL_CATEGORY_DETAIL_FAILED,
  EDIT_BIG_CATEGORY_FAILED,
  GET_SYSTEM_LANGUAGES_SUCCESS,
  GET_SYSTEM_LANGUAGES_FAILED,
  GET_CATEGORY_GALLERY,
  GET_CATEGORY_GALLERY_FAILED,
  GET_CATEGORY_GALLERY_SUCCESS,
} from "../actions/categoryManagement.action";

const INITIAL_STATE = {
  categoryList: {
    data: [],
    total: 0,
    page: 1,
    search: "",
  },
  systemLanguages: [],
  categoryDetail: {
    childCategories: {
      total: 0,
      data: [],
    },
  },
  smallCategoryDetail: null,
  error: {},
  loading: false,
  categoryGallery: {},
};

export default function categoryManagementReducer(
  state = INITIAL_STATE,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORY_LIST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_CATEGORY_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        categoryList: payload,
      });
    case GET_CATEGORY_LIST_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_BIG_CATEGORY_DETAIL:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_BIG_CATEGORY_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        categoryDetail: payload.body,
      });
    case GET_BIG_CATEGORY_DETAIL_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case CLEAR_CATEGORY_MANAGEMENT_STATE:
      return INITIAL_STATE;
    case GET_SMALL_CATEGORY_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        smallCategoryDetail: payload.body,
      });
    case GET_SMALL_CATEGORY_DETAIL_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case EDIT_BIG_CATEGORY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_SYSTEM_LANGUAGES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        systemLanguages: payload,
      });
    case GET_SYSTEM_LANGUAGES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_CATEGORY_GALLERY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_CATEGORY_GALLERY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        categoryGallery: payload,
      });
    case GET_CATEGORY_GALLERY_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
