import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_USER_GRAPH,
  GET_USER_GRAPH_SUCCESS,
  GET_USER_GRAPH_FAILED,
  GET_USER_ACTIVE_GRAPH_FAILED,
  GET_USER_ACTIVE_GRAPH_SUCCESS,
  GET_USER_ACTIVE_GRAPH,
} from "../actions/userGraph.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";


const getUserGraphFromApi = async (query) => {
  const url = `${apiUrl}/dashboard/users/graph?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getUserGraph(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getUserGraphFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_USER_GRAPH_SUCCESS,
        payload: { data: result.body, query: action.payload },
      });
    } else {
      yield put({ type: GET_USER_GRAPH_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_USER_GRAPH_FAILED, payload: e });
  }
}

const getUserActiveGraphFromApi = async (query) => {
  console.log({ query })
  const url = `${apiUrl}/dashboard/users/graph/active?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getUserActiveGraph(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getUserActiveGraphFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_USER_ACTIVE_GRAPH_SUCCESS,
        payload: { data: result.body, query: action.payload },
      });
    } else {
      yield put({ type: GET_USER_ACTIVE_GRAPH_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_USER_ACTIVE_GRAPH_FAILED, payload: e });
  }
}

export default function* dashboardSaga() {
  yield all([
    takeLatest(GET_USER_GRAPH, getUserGraph),
    takeLatest(GET_USER_ACTIVE_GRAPH, getUserActiveGraph),
  ]);
}
