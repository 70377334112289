export const GET_COUNTRIES = "[COUNTRY] GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "[COUNTRY] GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "[COUNTRY] GET_COUNTRIES_FAILED";

export const CREATE_COUNTRY = "[COUNTRY] CREATE_COUNTRY";
export const CREATE_COUNTRY_SUCCESS = "[COUNTRY] CREATE_COUNTRY_SUCCESS";
export const CREATE_COUNTRY_FAILED = "[COUNTRY] CREATE_COUNTRY_FAILED";

export const UPDATE_COUNTRY = "[COUNTRY] UPDATE_COUNTRY";
export const UPDATE_COUNTRY_SUCCESS = "[COUNTRY] UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_FAILED = "[COUNTRY] UPDATE_COUNTRY_FAILED";

export const DELETE_COUNTRY = "[COUNTRY] DELETE_COUNTRY";
export const DELETE_COUNTRY_SUCCESS = "[COUNTRY] DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAILED = "[COUNTRY] DELETE_COUNTRY_FAILED";
