import {
  GET_UI,
  GET_UI_SUCCESS,
  GET_UI_FAILED,
  CREATE_UI,
  CREATE_UI_SUCCESS,
  CREATE_UI_FAILED,
  UPDATE_UI,
  UPDATE_UI_SUCCESS,
  UPDATE_UI_FAILED,
  DELETE_UI,
  DELETE_UI_SUCCESS,
  DELETE_UI_FAILED
} from "../actions/uiManagement.action";

const INITIAL_STATE = {
  data: [],
  error: {},
  loading: false,
};

export default function uiManagementReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_UI:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_UI_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: payload,
      });
    case GET_UI_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case CREATE_UI:
      return Object.assign({}, state, {
        loading: true,
      });
    case CREATE_UI_SUCCESS:
      return state;
    case CREATE_UI_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case UPDATE_UI:
      return Object.assign({}, state, {
        loading: true,
      });
    case UPDATE_UI_SUCCESS:
      return state;
    case UPDATE_UI_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case DELETE_UI:
      return Object.assign({}, state, {
        loading: true,
      });
    case DELETE_UI_SUCCESS:
      return state;
    case DELETE_UI_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
