import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_CITIES,
  GET_CITIES_FAILED,
  GET_CITIES_SUCCESS,
  CREATE_CITY,
  CREATE_CITY_FAILED,
  UPDATE_CITY,
  UPDATE_CITY_FAILED,
  DELETE_CITY,
  DELETE_CITY_FAILED,
} from "../actions/cityManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getCitiesFromApi = async (query) => {
  const url = `${apiUrl}/cities?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getCities(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getCitiesFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_CITIES_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_CITIES_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_CITIES_FAILED, payload: e });
  }
}


const createCityFromApi = async (data) => {
  const url = `${apiUrl}/cities`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createCity(action) {
  try {
    const result = yield call(() => createCityFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_CITIES,
      });
      toast.success("Create city success");
    } else {
      yield put({ type: CREATE_CITY_FAILED, payload: result.error });
      toast.error("Create city failed");
    }
  } catch (e) {
    yield put({ type: CREATE_CITY_FAILED, payload: e });
    toast.error("Create city failed");
  }
}

const updateCityFromApi = async (id, data) => {
  const url = `${apiUrl}/cities/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

const deleteCityFromApi = async (id) => {
  const url = `${apiUrl}/cities/${id}`;
  return requestApi(url, 'DELETE');
};

function* updateCity(action) {
  try {
    const { payload, cityId } = action;
    const result = yield call(() => updateCityFromApi(cityId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_CITIES,
      });
      toast.success("Update city success");
    } else {
      yield put({ type: UPDATE_CITY_FAILED, payload: result.error });
      toast.error("Update city failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_CITY_FAILED, payload: e });
    toast.error("Update city failed");
  }
}

function* deleteCity(action) {
  try {
    const { payload, cityId } = action;
    const result = yield call(() => deleteCityFromApi(cityId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_CITIES,
      });
      toast.success("Delete city success");
    } else {
      yield put({ type: DELETE_CITY_FAILED, payload: result.error });
      toast.error("Delete city failed");
    }
  } catch (e) {
    yield put({ type: DELETE_CITY_FAILED, payload: e });
    toast.error("Delete city failed");
  }
}

export default function* citiesSaga() {
  yield all([
    takeLatest(GET_CITIES, getCities),
    takeLatest(CREATE_CITY, createCity),
    takeLatest(UPDATE_CITY, updateCity),
    takeLatest(DELETE_CITY, deleteCity),
  ]);
}
