import { pickBy, identity } from 'lodash';
import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAILED,
  GET_BIG_CATEGORY_DETAIL,
  GET_BIG_CATEGORY_DETAIL_SUCCESS,
  GET_BIG_CATEGORY_DETAIL_FAILED,
  DELETE_BIG_CATEGORY,
  DELETE_BIG_CATEGORY_FAILED,
  ADD_BIG_CATEGORY,
  ADD_BIG_CATEGORY_FAILED,
  ADD_SMALL_CATEGORY,
  ADD_SMALL_CATEGORY_FAILED,
  DELETE_SMALL_CATEGORY,
  DELETE_SMALL_CATEGORY_FAILED,
  GET_SMALL_CATEGORY_DETAIL,
  GET_SMALL_CATEGORY_DETAIL_SUCCESS,
  GET_SMALL_CATEGORY_DETAIL_FAILED,
  EDIT_BIG_CATEGORY,
  EDIT_BIG_CATEGORY_FAILED,
  EDIT_SMALL_CATEGORY,
  EDIT_SMALL_CATEGORY_FAILED,
  UPDATE_CATERGORY_ORDER_FAILED,
  UPDATE_CATERGORY_ORDER,
  UPDATE_SMALL_CATERGORY_ORDER,
  UPDATE_SMALL_CATERGORY_ORDER_FAILED,
  GET_SYSTEM_LANGUAGES,
  GET_SYSTEM_LANGUAGES_SUCCESS,
  GET_SYSTEM_LANGUAGES_FAILED,
  GET_CATEGORY_GALLERY,
  GET_CATEGORY_GALLERY_SUCCESS,
  GET_CATEGORY_GALLERY_FAILED,
  DELETE_CATEGORY_GALLERY,
  ADD_CATEGORY_GALLERY,
} from "../actions/categoryManagement.action";
import { requestApi, apiRequestFilePost } from "../utils/requestHelper";
import { apiUrl } from "../config/config";
import { toast } from "react-toastify";

const getCategoryListFromApi = async (params) => {
  const url = `${apiUrl}/categories?${new URLSearchParams(params)}`;
  return requestApi(url, "GET");
};

function* getCategoryList(action) {
  try {
    const result = yield call(() => getCategoryListFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_CATEGORY_LIST_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_CATEGORY_LIST_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_CATEGORY_LIST_FAILED, payload: e });
    toast.error("Get category list failed");
  }
}

const getCategoryDetailFromApi = async (categoryId) => {
  const url = `${apiUrl}/categories/${categoryId}`;
  return requestApi(url, "GET");
};

function* getBigCategoryDetail(action) {
  try {
    const result = yield call(() => getCategoryDetailFromApi(action.payload));
    if (result.status === 200) {
      let childCategories;
      try {
        const childCategoiesRequest = yield call(() =>
          getCategoryListFromApi({ parentId: action.payload })
        );
        childCategories = childCategoiesRequest.body;
      } catch {
        childCategories = {
          data: [],
          total: 0,
        };
      }
      result.body.childCategories = childCategories;
      yield put({ type: GET_BIG_CATEGORY_DETAIL_SUCCESS, payload: result });
    } else {
      yield put({
        type: GET_BIG_CATEGORY_DETAIL_FAILED,
        payload: result.error,
      });
      toast.error("Get category failed");
    }
  } catch (e) {
    yield put({ type: GET_BIG_CATEGORY_DETAIL_FAILED, payload: e });
    toast.error("Get category failed");
  }
}

const deleteCategoryFromApi = async (categoryId) => {
  const url = `${apiUrl}/categories/${categoryId}`;
  return requestApi(url, "DELETE");
};

function* deleteBigCategory(action) {
  try {
    const result = yield call(() =>
      deleteCategoryFromApi(action.payload.categoryId)
    );
    if (result.status === 201) {
      yield put({
        type: GET_CATEGORY_LIST,
        payload: action.payload.params,
      });
      toast.success("Delete category success");
    } else {
      yield put({
        type: DELETE_BIG_CATEGORY_FAILED,
        payload: result.error,
      });
      toast.error("Delete category failed");
    }
  } catch (e) {
    yield put({ type: DELETE_BIG_CATEGORY_FAILED, payload: e });
    toast.error("Delete category failed");
  }
}

const addBigCategoryFromApi = async (data) => {
  const { uploadImage, uploadIcon, ...rest } = data;
  const url = `${apiUrl}/categories`;
  return apiRequestFilePost(
    url,
    { icon: uploadIcon.image, image: uploadImage.image },
    { ...rest, is_new: true, is_status: true, is_order: true }
  );
};

function* addBigCategory(action) {
  try {
    const result = yield call(() => addBigCategoryFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_CATEGORY_LIST,
        payload: { parentId: action.payload.parent_id },
      });
      toast.success("Add category success");
    } else {
      yield put({
        type: ADD_BIG_CATEGORY_FAILED,
        payload: result.error,
      });
      toast.error(result.message);
    }
  } catch (e) {
    yield put({ type: ADD_BIG_CATEGORY_FAILED, payload: e });
    toast.error("Add category failed");
  }
}

const addSmallCategoryFromApi = async (data) => {
  const { uploadImage, parentId, ...rest } = data;
  const { image } = uploadImage;
  const url = `${apiUrl}/categories`;
  return apiRequestFilePost(
    url,
    { image },
    {
      ...rest,
      parent_id: parentId,
      is_new: true,
      is_status: true,
      is_order: true,
    }
  );
};

function* addSmallCategory(action) {
  try {
    const result = yield call(() => addSmallCategoryFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_BIG_CATEGORY_DETAIL,
        payload: action.payload.parentId,
      });
      toast.success("Add category success");
    } else {
      yield put({
        type: ADD_SMALL_CATEGORY_FAILED,
        payload: result.error,
      });
      toast.error(result.message);
    }
  } catch (e) {
    yield put({ type: ADD_SMALL_CATEGORY_FAILED, payload: e });
    toast.error("Add category failed");
  }
}

function* deleteSmallCategory(action) {
  try {
    const result = yield call(() =>
      deleteCategoryFromApi(action.payload.categoryId)
    );
    if (result.status === 201) {
      yield put({
        type: GET_BIG_CATEGORY_DETAIL,
        payload: action.payload.parentId,
      });
      toast.success("Delete category success");
    } else {
      yield put({
        type: DELETE_SMALL_CATEGORY_FAILED,
        payload: result.error,
      });
      toast.error("Delete category failed");
    }
  } catch (e) {
    yield put({ type: DELETE_SMALL_CATEGORY_FAILED, payload: e });
    toast.error("Delete category failed");
  }
}

function* getSmallCategoryDetail(action) {
  try {
    const result = yield call(() => getCategoryDetailFromApi(action.payload));
    if (result.status === 200) {
      yield put({ type: GET_SMALL_CATEGORY_DETAIL_SUCCESS, payload: result });
    } else {
      yield put({
        type: GET_SMALL_CATEGORY_DETAIL_FAILED,
        payload: result.error,
      });
    }
  } catch (e) {
    yield put({ type: GET_SMALL_CATEGORY_DETAIL_FAILED, payload: e });
    toast.error("Get category failed");
  }
}

const editBigCategoryFromApi = async (data) => {
  const url = `${apiUrl}/categories/${data.categoryId}`;
  const { uploadIcon, uploadImage, uploadIconActive } = data.body;

  const uploadFiles = {};
  const body = {
    ...pickBy(data.body, identity),
  };

  if (uploadIcon) {
    uploadFiles.icon = uploadIcon.image;
    delete body.uploadIcon
  }

  if (uploadIconActive) {
    uploadFiles.icon_active = uploadIconActive.image;
    delete body.uploadIconActive
  }

  if (uploadImage) {
    uploadFiles.image = uploadImage.image;
    delete body.uploadImage
  }

  return apiRequestFilePost(url, uploadFiles, body, "PUT");
};

function* editBigCategory(action) {
  try {
    const result = yield call(() => editBigCategoryFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_BIG_CATEGORY_DETAIL,
        payload: action.payload.categoryId,
      });
      toast.success("Edit category success");
    } else {
      yield put({
        type: EDIT_BIG_CATEGORY_FAILED,
        payload: result.error,
      });
      toast.error("Edit category failed");
    }
  } catch (e) {
    yield put({ type: EDIT_BIG_CATEGORY_FAILED, payload: e });
    toast.error("Edit category failed");
  }
}

function* editSmallCategory(action) {
  try {
    const result = yield call(() => editBigCategoryFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_SMALL_CATEGORY_DETAIL,
        payload: action.payload.categoryId,
      });
      yield put({
        type: GET_BIG_CATEGORY_DETAIL,
        payload: action.payload.parentId,
      });
      toast.success("Edit category success");
    } else {
      yield put({
        type: EDIT_SMALL_CATEGORY_FAILED,
        payload: result.error,
      });
      toast.error("Edit category failed");
    }
  } catch (e) {
    yield put({ type: EDIT_SMALL_CATEGORY_FAILED, payload: e });
    toast.error("Edit category failed");
  }
}

const updateCategoryOrderFromApi = async (body) => {
  const url = `${apiUrl}/categories/order-top`;
  return requestApi(url, "PUT", JSON.stringify(body));
};

function* updateCategoryOrder(action) {
  try {
    const result = yield call(() =>
      updateCategoryOrderFromApi(action.payload.body)
    );
    if (result.status === 200) {
      yield put({
        type: GET_CATEGORY_LIST,
        payload: action.payload.params,
      });
      toast.success("Update category order success");
    } else {
      yield put({
        type: UPDATE_CATERGORY_ORDER_FAILED,
        payload: result.error,
      });
      toast.error("Update category order failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_CATERGORY_ORDER_FAILED, payload: e });
    toast.error("Edit category failed");
  }
}

function* updateSmallCategoryOrder(action) {
  try {
    const result = yield call(() =>
      updateCategoryOrderFromApi(action.payload.body)
    );
    if (result.status === 200) {
      yield put({
        type: GET_BIG_CATEGORY_DETAIL,
        payload: action.payload.categoryId,
      });
      toast.success("Update category order success");
    } else {
      yield put({
        type: UPDATE_SMALL_CATERGORY_ORDER_FAILED,
        payload: result.error,
      });
      toast.error("Update category order failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_SMALL_CATERGORY_ORDER_FAILED, payload: e });
    toast.error("Edit category failed");
  }
}

const getSystemLanguagesFromApi = async () => {
  const url = `${apiUrl}/system-languages`;
  return requestApi(url, "GET");
};

function* getSystemLanguages(action) {
  try {
    const results = yield call(() =>
      getSystemLanguagesFromApi()
    );
    if (results.status === 200) {
      yield put({
        type: GET_SYSTEM_LANGUAGES_SUCCESS,
        payload: results.body,
      });
    } else {
      yield put({
        type: GET_SYSTEM_LANGUAGES_FAILED,
        payload: results.error,
      });
      toast.error("Get system languages failed");
    }
  } catch (e) {
    yield put({ type: GET_SYSTEM_LANGUAGES_FAILED, payload: e });
    toast.error("Edit category failed");
  }
}

const getCategoryGalleryFromApi = async (id) => {
  const url = `${apiUrl}/categories/${id}/galleries`;
  return requestApi(url, "GET");
};

function* getCategoryGallery(action) {
  try {
    const { payload } = action
    const results = yield call(() =>
      getCategoryGalleryFromApi(payload)
    );
    if (results.status === 200) {
      yield put({
        type: GET_CATEGORY_GALLERY_SUCCESS,
        payload: results.body,
      });
    } else {
      yield put({
        type: GET_CATEGORY_GALLERY_FAILED,
        payload: results.error,
      });
      toast.error("Get category galleries failed");
    }
  } catch (e) {
    yield put({ type: GET_CATEGORY_GALLERY_FAILED, payload: e });
    toast.error("Get category galleries failed");
  }
}


const deleteCategoryGalleryFromApi = async ({ id, image }) => {
  const url = `${apiUrl}/categories/${id}/galleries`;
  return requestApi(url, "DELETE", JSON.stringify({ image }));
};

function* deleteCategoryGallery(action) {
  try {
    const { payload } = action
    const results = yield call(() =>
      deleteCategoryGalleryFromApi(payload)
    );
    if (results.status === 200) {
      yield put({
        type: GET_CATEGORY_GALLERY,
        payload: payload.id
      });
    } else {
      yield put({
        type: GET_CATEGORY_GALLERY_FAILED,
        payload: results.error,
      });
      toast.error("Get category galleries failed");
    }
  } catch (e) {
    yield put({ type: GET_CATEGORY_GALLERY_FAILED, payload: e });
    toast.error("Get category galleries failed");
  }
}

const addImageToCategoryGalleryFromApi = async ({ id, images }) => {
  const url = `${apiUrl}/categories/${id}/galleries`;
  return apiRequestFilePost(url, images, null, 'PUT');
};

function* addImageToCategoryGallery(action) {
  try {
    const { payload } = action
    const results = yield call(() => addImageToCategoryGalleryFromApi(payload));
    if (results.status === 200) {
      window.location.reload();
    } else {
      yield put({
        type: GET_CATEGORY_GALLERY_FAILED,
        payload: results.error,
      });
      toast.error("Add category galleries failed");
    }
  } catch (e) {
    yield put({ type: GET_CATEGORY_GALLERY_FAILED, payload: e });
    toast.error("Add category galleries failed");
  }
}


export default function* categoryManagementSaga() {
  yield all([
    takeLatest(GET_CATEGORY_LIST, getCategoryList),
    takeLatest(GET_BIG_CATEGORY_DETAIL, getBigCategoryDetail),
    takeLatest(DELETE_BIG_CATEGORY, deleteBigCategory),
    takeLatest(ADD_BIG_CATEGORY, addBigCategory),
    takeLatest(ADD_SMALL_CATEGORY, addSmallCategory),
    takeLatest(DELETE_SMALL_CATEGORY, deleteSmallCategory),
    takeLatest(GET_SMALL_CATEGORY_DETAIL, getSmallCategoryDetail),
    takeLatest(EDIT_BIG_CATEGORY, editBigCategory),
    takeLatest(EDIT_SMALL_CATEGORY, editSmallCategory),
    takeLatest(UPDATE_CATERGORY_ORDER, updateCategoryOrder),
    takeLatest(UPDATE_SMALL_CATERGORY_ORDER, updateSmallCategoryOrder),
    takeLatest(GET_SYSTEM_LANGUAGES, getSystemLanguages),
    takeLatest(GET_CATEGORY_GALLERY, getCategoryGallery),
    takeLatest(DELETE_CATEGORY_GALLERY, deleteCategoryGallery),
    takeLatest(ADD_CATEGORY_GALLERY, addImageToCategoryGallery),
  ]);
}
