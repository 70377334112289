import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_UI,
  GET_UI_SUCCESS,
  GET_UI_FAILED,
  CREATE_UI_SUCCESS,
  CREATE_UI_FAILED,
  CREATE_UI,
  UPDATE_UI,
  UPDATE_UI_FAILED,
  DELETE_UI_FAILED,
  DELETE_UI
} from "../actions/uiManagement.action";
import { requestApi, apiRequestFilePost } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getUIFromApi = async (query) => {
  const url = `${apiUrl}/ui-management?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getUI(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getUIFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_UI_SUCCESS,
        payload: result.body.body,
      });
    } else {
      yield put({ type: GET_UI_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_UI_FAILED, payload: e });
  }
}

const createUIFromApi = async (payload, file, type, languageIds, images) => {
  const url = `${apiUrl}/ui-management`;
  return apiRequestFilePost(
    url,
    { file: [file], images },
    { payload: JSON.stringify(payload), type, languageIds: JSON.stringify(languageIds) }
  )
};

function* createUI(action) {
  try {
    const { payload, file, typeQuery, languageIds, images } = action;
    const result = yield call(() => createUIFromApi(payload, file, typeQuery, languageIds, images));
    if (result.status === 201) {
      yield put({
        type: GET_UI, payload: { type: typeQuery }
      });
    } else {
      yield put({ type: CREATE_UI_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: CREATE_UI_FAILED, payload: e });
  }
}

const updateUIFromApi = async (id, payload, file, type, languageIds, images) => {
  const url = `${apiUrl}/ui-management/${id}`;
  return apiRequestFilePost(
    url,
    { file: [file], images },
    { payload: JSON.stringify(payload), type, languageIds: JSON.stringify(languageIds) }
  )
};

function* updateUI(action) {
  try {
    const { payload, file, typeQuery, id, languageIds, images } = action;
    const result = yield call(() => updateUIFromApi(id, payload, file, typeQuery, languageIds, images));
    if (result.status === 200) {
      yield put({
        type: GET_UI, payload: { type: typeQuery }
      });
    } else {
      yield put({ type: UPDATE_UI_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: UPDATE_UI_FAILED, payload: e });
  }
}

const deleteUIFromApi = async (id) => {
  const url = `${apiUrl}/ui-management/${id}`;
  return requestApi(
    url,
    "DELETE"
  )
};

function* deleteUI(action) {
  try {
    const { typeQuery, id } = action;
    const result = yield call(() => deleteUIFromApi(id));
    if (result.status === 200) {
      yield put({
        type: GET_UI, payload: { type: typeQuery }
      });
    } else {
      yield put({ type: DELETE_UI_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: DELETE_UI_FAILED, payload: e });
  }
}

export default function* uiManagementSaga() {
  yield all([
    takeLatest(GET_UI, getUI),
    takeLatest(CREATE_UI, createUI),
    takeLatest(UPDATE_UI, updateUI),
    takeLatest(DELETE_UI, deleteUI)
  ]);
}
