import {
  FETCH_LIST_TRACKING,
  FETCH_LIST_TRACKING_FAILED,
  FETCH_LIST_TRACKING_SUCCESS,
} from "../actions/trackingManagement.action";

const INITIAL_STATE = {
  tracking: {
    data: {},
    total: 0,
    page: 1,
    limit: 20,
  },
  error: {},
  loading: false,
};

export default function degreeReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LIST_TRACKING:
      return Object.assign({}, state, {
        loading: true,
      });
    case FETCH_LIST_TRACKING_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        tracking: {
          ...payload,
        },
      });
    case FETCH_LIST_TRACKING_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
