import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILED,
  CREATE_CURRENCY,
  CREATE_CURRENCY_FAILED,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAILED,
  DELETE_CURRENCY,
  DELETE_CURRENCY_FAILED
} from "../actions/currencyManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getCurrencyFromApi = async () => {
  const url = `${apiUrl}/currencies`;
  return requestApi(url, "GET");
};

function* getCurrency() {
  try {
    const result = yield call(() => getCurrencyFromApi());
    if (result.status === 200) {
      yield put({
        type: GET_CURRENCIES_SUCCESS,
        payload: result.body,
      });
    } else {
      yield put({ type: GET_CURRENCIES_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_CURRENCIES_FAILED, payload: e });
  }
}

const createCurrencyFromApi = async (payload) => {
  const url = `${apiUrl}/currencies`;
  return requestApi(
    url,
    "POST",
    JSON.stringify(payload)
  )
};

function* createCurrency(action) {
  try {
    const { payload } = action;
    const result = yield call(() => createCurrencyFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_CURRENCIES
      });
    } else {
      yield put({ type: CREATE_CURRENCY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: CREATE_CURRENCY_FAILED, payload: e });
  }
}

const updateCurrencyFromApi = async (id, payload) => {
  const url = `${apiUrl}/currencies/${id}`;
  return requestApi(
    url,
    "POST",
    JSON.stringify(payload)
  )
};

function* updateCurrency(action) {
  try {
    const { payload, id } = action;
    const result = yield call(() => updateCurrencyFromApi(id, payload));
    if (result.status === 200) {
      yield put({
        type: GET_CURRENCIES
      });
    } else {
      yield put({ type: UPDATE_CURRENCY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: UPDATE_CURRENCY_FAILED, payload: e });
  }
}

const deleteCurrencyFromApi = async (id) => {
  const url = `${apiUrl}/currencies/${id}`;
  return requestApi(
    url,
    "DELETE"
  )
};

function* deleteCurrency(action) {
  try {
    const { id } = action;
    const result = yield call(() => deleteCurrencyFromApi(id));
    if (result.status === 200) {
      yield put({
        type: GET_CURRENCIES
      });
    } else {
      yield put({ type: DELETE_CURRENCY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: DELETE_CURRENCY_FAILED, payload: e });
  }
}

export default function* currencyManagementSaga() {
  yield all([
    takeLatest(GET_CURRENCIES, getCurrency),
    takeLatest(CREATE_CURRENCY, createCurrency),
    takeLatest(UPDATE_CURRENCY, updateCurrency),
    takeLatest(DELETE_CURRENCY, deleteCurrency)
  ]);
}
