import {
  GET_GIG_LIST,
  GET_GIG_LIST_SUCCESS,
  GET_GIG_LIST_FAILED,
  CLEAR_GIG_MANAGEMENT_STATE,
  GET_GIG_DETAIL_SUCCESS,
  GET_GIG_DETAIL_FAILED,
  CANCEL_GIG_DETAIL,
} from "../actions/gigManagement.action";

const INITIAL_STATE = {
  gigList: {
    data: [],
    total: 0,
    page: 1,
    status: "",
    search: "",
    blacklistStatus: 0,
  },
  gigDetail: {},
  error: {},
  loading: false,
  showDetailModal: false,
};

export default function userManagementReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_GIG_LIST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_GIG_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        gigList: payload,
      });
    case GET_GIG_LIST_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case CLEAR_GIG_MANAGEMENT_STATE:
      return INITIAL_STATE;
    case GET_GIG_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        gigDetail: payload,
        showDetailModal: true,
      });
    case GET_GIG_DETAIL_FAILED:
      return Object.assign({}, state, {
        error: payload,
      });
    case CANCEL_GIG_DETAIL:
      return Object.assign({}, state, {
        gigDetail: {},
        showDetailModal: false,
      });
    default:
      return state;
  }
}
