export const GET_OFFER_LIST = "[OFFER_MANAGEMENT] GET_OFFERS";
export const GET_OFFER_LIST_SUCCESS = "[OFFER_MANAGEMENT] GET_OFFER_SUCCESS";
export const GET_OFFER_LIST_FAILED = "[OFFER_MANAGEMENT] GET_OFFER_FAILED";
export const CLEAR_OFFER_MANAGEMENT_STATE =
  "[OFFER_MANAGEMENT] CLEAR_OFFER_MANAGEMENT_STATE";

export const APPROVE_OFFER = "[OFFER_MANAGEMENT] APPROVE_OFFER";
export const APPROVE_OFFER_FAILED = "[OFFER_MANAGEMENT] APPROVE_OFFER_FAILED";

export const DENY_OFFER = "[OFFER_MANAGEMENT] DENY_OFFER";
export const DENY_OFFER_FAILED = "[OFFER_MANAGEMENT] DENY_OFFER_FAILED";

export const GET_OFFER_DETAIL = "[OFFER_MANAGEMENT] GET_OFFER_DETAIL";
export const GET_OFFER_DETAIL_SUCCESS =
  "[OFFER_MANAGEMENT] GET_OFFER_DETAIL_SUCCESS";
export const GET_OFFER_DETAIL_FAILED =
  "[OFFER_MANAGEMENT] GET_OFFER_DETAIL_FAILED";
export const CANCEL_OFFER_DETAIL = "[OFFER_MANAGEMENT] CANCEL_OFFER_DETAIL";
