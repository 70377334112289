import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_ORDER_LIST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_FAILED,
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAILED,
} from "../actions/orderManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

async function getOrderListFromApi(params) {
  const url = `${apiUrl}/orders?${new URLSearchParams(params)}`;
  return requestApi(url, "GET");
}

function* getOrderList(action) {
  try {
    const result = yield call(() => getOrderListFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_ORDER_LIST_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_ORDER_LIST_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_ORDER_LIST_FAILED, payload: e });
  }
}

async function getOrderDetailFromApi(offerId) {
  const url = `${apiUrl}/orders/${offerId}`;
  return requestApi(url, "GET");
}

function* getOrderDetail(action) {
  try {
    const result = yield call(() => getOrderDetailFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_ORDER_DETAIL_SUCCESS,
        payload: result.body,
      });
    } else {
      yield put({ type: GET_ORDER_DETAIL_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_ORDER_DETAIL_FAILED, payload: e });
  }
}

export default function* orderManagementSaga() {
  yield all([
    takeLatest(GET_ORDER_LIST, getOrderList),
    takeLatest(GET_ORDER_DETAIL, getOrderDetail),
  ]);
}
