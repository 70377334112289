import { all, fork } from "redux-saga/effects";
import watchUserSagasAsync from "./user.saga";
import userManagementSaga from "./userManagement.saga";
import gigManagementSaga from "./gigManagement.saga";
import offerManagementSaga from "./offerManagement.saga";
import orderManagementSaga from "./orderManagement.saga";
import categoryManagementSaga from "./categoryManagement.saga";
import keywordSaga from "./keyword.saga";
import tagManagementSaga from "./tagManagement.saga";
import languageManagementSaga from "./languageManagement.saga";
import levelManagementSaga from "./levelManagement.saga";
import countryManagementSaga from "./countryManagement.saga";
import cityManagementSaga from "./cityManagement.saga";
import uiManagementSaga from "./uiManagement.saga"
import degreeManagementSaga from "./degreeManagement.saga";
import languageSystemManagementSaga from "./languageSystemManagement.saga";
import rateOptionManagementSaga from "./rateOptionManagement.saga";
import packageManagementSaga from "./packageManagement.saga";
import informationManagementSaga from "./informationManagement.saga";
import currencyManagementSaga from "./currencyManagement.saga"
import newRegistersSaga from "./newRegisterManagement.saga";
import dashboardSaga from "./dashboard.saga";
import userGraphSaga from "./userGraph.saga";
import campaignManagementSaga from "./campaignManagement.saga";
import blacklistManagementSaga from "./blacklistManagement.saga";
import sendEmailManagementSaga from "./sendEmailManagement.saga";
import trackingManagementSaga from "./trackingManagement.saga";
import trackingAnalysisSaga from "./trackingAnalysis.saga";
import messageRoomStatisticSaga from "./messageRoomStatistic.saga";

export default function* sagas() {
  yield all([
    fork(watchUserSagasAsync),
    fork(userManagementSaga),
    fork(gigManagementSaga),
    fork(offerManagementSaga),
    fork(orderManagementSaga),
    fork(categoryManagementSaga),
    fork(keywordSaga),
    fork(tagManagementSaga),
    fork(languageManagementSaga),
    fork(levelManagementSaga),
    fork(countryManagementSaga),
    fork(cityManagementSaga),
    fork(uiManagementSaga),
    fork(degreeManagementSaga),
    fork(languageSystemManagementSaga),
    fork(rateOptionManagementSaga),
    fork(packageManagementSaga),
    fork(informationManagementSaga),
    fork(currencyManagementSaga),
    fork(newRegistersSaga),
    fork(dashboardSaga),
    fork(userGraphSaga),
    fork(campaignManagementSaga),
    fork(blacklistManagementSaga),
    fork(sendEmailManagementSaga),
    fork(trackingManagementSaga),
    fork(trackingAnalysisSaga),
    fork(messageRoomStatisticSaga),
  ]);
}
