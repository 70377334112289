export const GET_SUMMARY_USERS = "[DASHBOARD] GET_SUMMARY_USERS";
export const GET_SUMMARY_USERS_SUCCESS = "[DASHBOARD] GET_SUMMARY_USERS_SUCCESS";
export const GET_SUMMARY_USERS_FAILED = "[DASHBOARD] GET_SUMMARY_USERS_FAILED";

export const GET_ACTIVE_USERS = "[DASHBOARD] GET_ACTIVE_USERS";
export const GET_ACTIVE_USERS_SUCCESS = "[DASHBOARD] GET_ACTIVE_USERS_SUCCESS";
export const GET_ACTIVE_USERS_FAILED = "[DASHBOARD] GET_ACTIVE_USERS_FAILED";

export const GET_SUMMARY_JOBS = "[DASHBOARD] GET_SUMMARY_JOBS";
export const GET_SUMMARY_JOBS_SUCCESS = "[DASHBOARD] GET_SUMMARY_JOBS_SUCCESS";
export const GET_SUMMARY_JOBS_FAILED = "[DASHBOARD] GET_SUMMARY_JOBS_FAILED";

export const GET_JOBS_BY_STATUS = "[DASHBOARD] GET_JOBS_BY_STATUS";
export const GET_JOBS_BY_STATUS_SUCCESS = "[DASHBOARD] GET_JOBS_BY_STATUS_SUCCESS";
export const GET_JOBS_BY_STATUS_FAILED = "[DASHBOARD] GET_JOBS_BY_STATUS_FAILED";

export const GET_SUMMARY_PRICE = "[DASHBOARD] GET_SUMMARY_PRICE";
export const GET_SUMMARY_PRICE_SUCCESS = "[DASHBOARD] GET_SUMMARY_PRICE_SUCCESS";
export const GET_SUMMARY_PRICE_FAILED = "[DASHBOARD] GET_SUMMARY_PRICE_FAILED";

export const GET_SUMMARY_PRICE_BY_STATUS = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS";
export const GET_SUMMARY_PRICE_BY_STATUS_SUCCESS = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_SUCCESS";
export const GET_SUMMARY_PRICE_BY_STATUS_FAILED = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_FAILED";

export const GET_SUMMARY_REVENUE_BY_STATUS = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS";
export const GET_SUMMARY_REVENUE_BY_STATUS_SUCCESS = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_SUCCESS";
export const GET_SUMMARY_REVENUE_BY_STATUS_FAILED = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_FAILED";

export const GET_DASHBOARD_SUMMARY_USER = "[DASHBOARD] GET_DASHBOARD_SUMMARY_USER";
export const GET_DASHBOARD_SUMMARY_USER_SUCCESS = "[DASHBOARD] GET_DASHBOARD_SUMMARY_USER_SUCCESS";
export const GET_DASHBOARD_SUMMARY_USER_FAILED = "[DASHBOARD] GET_DASHBOARD_SUMMARY_USER_FAILED";

export const GET_DASHBOARD_SUMMARY_GIG = "[DASHBOARD] GET_DASHBOARD_SUMMARY_GIG";
export const GET_DASHBOARD_SUMMARY_GIG_SUCCESS = "[DASHBOARD] GET_DASHBOARD_SUMMARY_GIG_SUCCESS";
export const GET_DASHBOARD_SUMMARY_GIG_FAILED = "[DASHBOARD] GET_DASHBOARD_SUMMARY_GIG_FAILED";

export const GET_DASHBOARD_SUMMARY_TRANSACTION = "[DASHBOARD] GET_DASHBOARD_SUMMARY_TRANSACTION";
export const GET_DASHBOARD_SUMMARY_TRANSACTION_SUCCESS = "[DASHBOARD] GET_DASHBOARD_SUMMARY_TRANSACTION_SUCCESS";
export const GET_DASHBOARD_SUMMARY_TRANSACTION_FAILED = "[DASHBOARD] GET_DASHBOARD_SUMMARY_TRANSACTION_FAILED";

export const GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED = "[DASHBOARD] GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED";
export const GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_SUCCESS = "[DASHBOARD] GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_SUCCESS";
export const GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_FAILED = "[DASHBOARD] GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_FAILED";

export const GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG = "[DASHBOARD] GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG";
export const GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_SUCCESS = "[DASHBOARD] GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_SUCCESS";
export const GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_FAILED = "[DASHBOARD] GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_FAILED";

export const GET_ACTIVE_USERS_DAILY = "[DASHBOARD] GET_ACTIVE_USERS_DAILY";
export const GET_ACTIVE_USERS_DAILY_SUCCESS = "[DASHBOARD] GET_ACTIVE_USERS_DAILY_SUCCESS";
export const GET_ACTIVE_USERS_DAILY_FAILED = "[DASHBOARD] GET_ACTIVE_USERS_DAILY_FAILED";

export const GET_ACTIVE_USERS_WEEKLY = "[DASHBOARD] GET_ACTIVE_USERS_WEEKLY";
export const GET_ACTIVE_USERS_WEEKLY_SUCCESS = "[DASHBOARD] GET_ACTIVE_USERS_WEEKLY_SUCCESS";
export const GET_ACTIVE_USERS_WEEKLY_FAILED = "[DASHBOARD] GET_ACTIVE_USERS_WEEKLY_FAILED";

export const GET_ACTIVE_USERS_MONTHLY = "[DASHBOARD] GET_ACTIVE_USERS_MONTHLY";
export const GET_ACTIVE_USERS_MONTHLY_SUCCESS = "[DASHBOARD] GET_ACTIVE_USERS_MONTHLY_SUCCESS";
export const GET_ACTIVE_USERS_MONTHLY_FAILED = "[DASHBOARD] GET_ACTIVE_USERS_MONTHLY_FAILED";

export const GET_JOBS_BY_STATUS_DAILY = "[DASHBOARD] GET_JOBS_BY_STATUS_DAILY";
export const GET_JOBS_BY_STATUS_DAILY_SUCCESS = "[DASHBOARD] GET_JOBS_BY_STATUS_DAILY_SUCCESS";
export const GET_JOBS_BY_STATUS_DAILY_FAILED = "[DASHBOARD] GET_JOBS_BY_STATUS_DAILY_FAILED";

export const GET_JOBS_BY_STATUS_WEEKLY = "[DASHBOARD] GET_JOBS_BY_STATUS_WEEKLY";
export const GET_JOBS_BY_STATUS_WEEKLY_SUCCESS = "[DASHBOARD] GET_JOBS_BY_STATUS_WEEKLY_SUCCESS";
export const GET_JOBS_BY_STATUS_WEEKLY_FAILED = "[DASHBOARD] GET_JOBS_BY_STATUS_WEEKLY_FAILED";

export const GET_JOBS_BY_STATUS_MONTHLY = "[DASHBOARD] GET_JOBS_BY_STATUS_MONTHLY";
export const GET_JOBS_BY_STATUS_MONTHLY_SUCCESS = "[DASHBOARD] GET_JOBS_BY_STATUS_MONTHLY_SUCCESS";
export const GET_JOBS_BY_STATUS_MONTHLY_FAILED = "[DASHBOARD] GET_JOBS_BY_STATUS_MONTHLY_FAILED";

export const GET_SUMMARY_PRICE_BY_STATUS_DAILY = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_DAILY";
export const GET_SUMMARY_PRICE_BY_STATUS_DAILY_SUCCESS = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_DAILY_SUCCESS";
export const GET_SUMMARY_PRICE_BY_STATUS_DAILY_FAILED = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_DAILY_FAILED";

export const GET_SUMMARY_PRICE_BY_STATUS_WEEKLY = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_WEEKLY";
export const GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_SUCCESS = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_SUCCESS";
export const GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_FAILED = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_FAILED";

export const GET_SUMMARY_PRICE_BY_STATUS_MONTHLY = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_MONTHLY";
export const GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_SUCCESS = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_SUCCESS";
export const GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_FAILED = "[DASHBOARD] GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_FAILED";

export const GET_SUMMARY_REVENUE_BY_STATUS_HOURLY = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_HOURLY";
export const GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_SUCCESS = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_SUCCESS";
export const GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_FAILED = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_FAILED";

export const GET_SUMMARY_REVENUE_BY_STATUS_DAILY = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_DAILY";
export const GET_SUMMARY_REVENUE_BY_STATUS_DAILY_SUCCESS = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_DAILY_SUCCESS";
export const GET_SUMMARY_REVENUE_BY_STATUS_DAILY_FAILED = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_DAILY_FAILED";

export const GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY";
export const GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_SUCCESS = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_SUCCESS";
export const GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_FAILED = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_FAILED";

export const GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY";
export const GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_SUCCESS = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_SUCCESS";
export const GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_FAILED = "[DASHBOARD] GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_FAILED";

export const GET_ALL_SUMMARY_REVENUE = "[DASHBOARD] GET_ALL_SUMMARY_REVENUE";
export const GET_ALL_SUMMARY_REVENUE_SUCCESS = "[DASHBOARD] GET_ALL_SUMMARY_REVENUE_SUCCESS";
export const GET_ALL_SUMMARY_REVENUE_FAILED = "[DASHBOARD] GET_ALL_SUMMARY_REVENUE_FAILED";
