export const GET_CAMPAIGNS = "[CAMPAIGN] GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "[CAMPAIGN] GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILED = "[CAMPAIGN] GET_CAMPAIGNS_FAILED";

export const CREATE_CAMPAIGN = "[CAMPAIGN] CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "[CAMPAIGN] CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILED = "[CAMPAIGN] CREATE_CAMPAIGN_FAILED";

export const UPDATE_CAMPAIGN = "[CAMPAIGN] UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "[CAMPAIGN] UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILED = "[CAMPAIGN] UPDATE_CAMPAIGN_FAILED";

export const DELETE_CAMPAIGN = "[CAMPAIGN] DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCESS = "[CAMPAIGN] DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILED = "[CAMPAIGN] DELETE_CAMPAIGN_FAILED";
