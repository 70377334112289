import { createStore, applyMiddleware, compose } from "redux";
// import { browserHistory } from "react-router";
import { BrowserRouter } from "react-router-dom";
import sagas from "../sagas/index";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";
import reducer from "../reducers/index";

// create the middleware with the userManager
// const oidcMiddleware = createOidcMiddleware(userManager);

const sagaMiddleware = createSagaMiddleware();
// const initialState = {};

const createStoreWithMiddleware = compose(
  applyMiddleware(sagaMiddleware, routerMiddleware(BrowserRouter))
)(createStore);

// const persistedState = localStorage.getItem("state")
//   ? JSON.parse(localStorage.getItem("state"))
//   : [];

const store = createStoreWithMiddleware(reducer, {});

// store.subscribe(() => {
//   localStorage.setItem("state", JSON.stringify(store.getState()));
// });

export default store;
sagaMiddleware.run(sagas);
