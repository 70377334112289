import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_TAGS,
  GET_TAGS_FAILED,
  GET_TAGS_SUCCESS,
  CREATE_TAG,
  CREATE_TAG_FAILED,
  UPDATE_TAG,
  UPDATE_TAG_FAILED,
  DELETE_TAG,
  DELETE_TAG_FAILED,
  UPDATE_BLACKLIST_STATUS,
  UPDATE_BLACKLIST_STATUS_SUCCESS,
  UPDATE_BLACKLIST_STATUS_FAILED,
} from "../actions/tagManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getTagsFromApi = async (query) => {
  const url = `${apiUrl}/tags?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getTags(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getTagsFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_TAGS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_TAGS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_TAGS_FAILED, payload: e });
  }
}


const createTagFromApi = async (data) => {
  const url = `${apiUrl}/tags`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createTag(action) {
  try {
    const result = yield call(() => createTagFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_TAGS,
      });
      toast.success("Create tag success");
    } else {
      yield put({ type: CREATE_TAG_FAILED, payload: result.error });
      toast.error("Create tag failed");
    }
  } catch (e) {
    yield put({ type: CREATE_TAG_FAILED, payload: e });
    toast.error("Create tag failed");
  }
}

const updateTagFromApi = async (id, data) => {
  const url = `${apiUrl}/tags/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updateTag(action) {
  try {
    const { payload, tagId } = action;
    const result = yield call(() => updateTagFromApi(tagId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_TAGS,
      });
      toast.success("Update tag success");
    } else {
      yield put({ type: UPDATE_TAG_FAILED, payload: result.error });
      toast.error("Update tag failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_TAG_FAILED, payload: e });
    toast.error("Update tag failed");
  }
}

const deleteTagFromApi = async (id) => {
  const url = `${apiUrl}/tags/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteTag(action) {
  try {
    const { payload, tagId } = action;
    const result = yield call(() => deleteTagFromApi(tagId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_TAGS,
      });
      toast.success("Delete tag success");
    } else {
      yield put({ type: DELETE_TAG_FAILED, payload: result.error });
      toast.error("Delete tag failed");
    }
  } catch (e) {
    yield put({ type: DELETE_TAG_FAILED, payload: e });
    toast.error("Delete tag failed");
  }
}

function* updateBlacklistStatus(action) {
  try {
    const { payload, tagId } = action;
    const result = yield call(() => updateTagFromApi(tagId, payload));
    if (result.status === 200) {
      yield put({
        type: UPDATE_BLACKLIST_STATUS_SUCCESS,
        payload: action.payload,
        tagId,
      });
    } else {
      yield put({ type: UPDATE_BLACKLIST_STATUS_FAILED, payload: result.error });
      toast.error("Update tag failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_BLACKLIST_STATUS_FAILED, payload: e });
    toast.error("Update tag failed");
  }
}

export default function* tagsSaga() {
  yield all([
    takeLatest(GET_TAGS, getTags),
    takeLatest(CREATE_TAG, createTag),
    takeLatest(UPDATE_TAG, updateTag),
    takeLatest(DELETE_TAG, deleteTag),
    takeLatest(UPDATE_BLACKLIST_STATUS, updateBlacklistStatus),
  ]);
}
