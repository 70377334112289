import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";
import { GET_NEW_REGISTER, GET_NEW_REGISTER_SUCCESS, GET_NEW_REGISTER_FAILED, DELETE_NEW_REGISTER_SUCCESS, DELETE_NEW_REGISTER_FAILED, DELETE_NEW_REGISTER } from "../actions/newRegisterManagement.action";

const getNewRegistersFromApi = async (query) => {
  const url = `${apiUrl}/register-information?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getNewRegisters(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getNewRegistersFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_NEW_REGISTER_SUCCESS,
        payload: result.body,
      });
    } else {
      yield put({ type: GET_NEW_REGISTER_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_NEW_REGISTER_FAILED, payload: e });
  }
}

const deleteNewRegistersFromApi = async (id) => {
  const url = `${apiUrl}/register-information/${id}`;
  return requestApi(url, "DELETE");
};

function* deleteNewRegisters(action) {
  try {
    const { registerId, page } = action;
    const result = yield call(() => deleteNewRegistersFromApi(registerId));
    if (result.status === 200) {
      yield put({
        type: GET_NEW_REGISTER,
        payload: { page },
      });
    } else {
      yield put({ type: DELETE_NEW_REGISTER_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: DELETE_NEW_REGISTER_FAILED, payload: e });
  }
}

export default function* newRegistersSaga() {
  yield all([
    takeLatest(GET_NEW_REGISTER, getNewRegisters),
    takeLatest(DELETE_NEW_REGISTER, deleteNewRegisters)
  ]);
}
