export const GET_LANGUAGES = "[LANGUAGE] GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "[LANGUAGE] GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAILED = "[LANGUAGE] GET_LANGUAGES_FAILED";

export const CREATE_LANGUAGE = "[LANGUAGE] CREATE_LANGUAGE";
export const CREATE_LANGUAGE_SUCCESS = "[LANGUAGE] CREATE_LANGUAGE_SUCCESS";
export const CREATE_LANGUAGE_FAILED = "[LANGUAGE] CREATE_LANGUAGE_FAILED";

export const UPDATE_LANGUAGE = "[LANGUAGE] UPDATE_LANGUAGE";
export const UPDATE_LANGUAGE_SUCCESS = "[LANGUAGE] UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAILED = "[LANGUAGE] UPDATE_LANGUAGE_FAILED";

export const DELETE_LANGUAGE = "[LANGUAGE] DELETE_LANGUAGE";
export const DELETE_LANGUAGE_SUCCESS = "[LANGUAGE] DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAILED = "[LANGUAGE] DELETE_LANGUAGE_FAILED";
