export const GET_KEYWORDS = "[KEYWORD] GET_KEYWORDS";
export const GET_KEYWORDS_SUCCESS = "[KEYWORD] GET_KEYWORDS_SUCCESS";
export const GET_KEYWORDS_FAILED = "[KEYWORD] GET_KEYWORDS_FAILED";

export const CREATE_KEYWORDS = "[KEYWORD] CREATE_KEYWORDS";
export const CREATE_KEYWORDS_SUCCESS = "[KEYWORD] CREATE_KEYWORDS_SUCCESS";
export const CREATE_KEYWORDS_FAILED = "[KEYWORD] CREATE_KEYWORDS_FAILED";

export const UPDATE_KEYWORDS = "[KEYWORD] UPDATE_KEYWORDS";
export const UPDATE_KEYWORDS_SUCCESS = "[KEYWORD] UPDATE_KEYWORDS_SUCCESS";
export const UPDATE_KEYWORDS_FAILED = "[KEYWORD] UPDATE_KEYWORDS_FAILED";

export const DELETE_KEYWORDS = "[KEYWORD] DELETE_KEYWORDS";
export const DELETE_KEYWORDS_SUCCESS = "[KEYWORD] DELETE_KEYWORDS_SUCCESS";
export const DELETE_KEYWORDS_FAILED = "[KEYWORD] DELETE_KEYWORDS_FAILED";

export const UPDATE_CACHE_KEYWORDS = "[KEYWORD] UPDATE_CACHE_KEYWORDS";
export const UPDATE_CACHE_KEYWORDS_SUCCESS = "[KEYWORD] UPDATE_CACHE_KEYWORDS_SUCCESS";
export const UPDATE_CACHE_KEYWORDS_FAILED = "[KEYWORD] UPDATE_CACHE_KEYWORDS_FAILED";

export const UPDATE_CACHE_GIGS = "[KEYWORD] UPDATE_CACHE_GIGS";
export const UPDATE_CACHE_GIGS_SUCCESS = "[KEYWORD] UPDATE_CACHE_GIGS_SUCCESS";
export const UPDATE_CACHE_GIGS_FAILED = "[KEYWORD] UPDATE_CACHE_GIGS_FAILED";

export const UPDATE_CACHE_USERNAME = "[KEYWORD] UPDATE_CACHE_USERNAME";
export const UPDATE_CACHE_USERNAME_SUCCESS = "[KEYWORD] UPDATE_CACHE_USERNAME_SUCCESS";
export const UPDATE_CACHE_USERNAME_FAILED = "[KEYWORD] UPDATE_CACHE_USERNAME_FAILED";

export const IMPORT_KEYWORD = "[KEYWORD] IMPORT_KEYWORD";
export const IMPORT_KEYWORD_SUCCESS = "[KEYWORD] IMPORT_KEYWORD_SUCCESS";
export const IMPORT_KEYWORD_FAILED = "[KEYWORD] IMPORT_KEYWORD_FAILED";
