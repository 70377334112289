import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  FETCH_MESSAGE_ROOM_STATISTIC,
  FETCH_MESSAGE_ROOM_STATISTIC_FAILED,
  FETCH_MESSAGE_ROOM_STATISTIC_SUCCESS,
} from "../actions/messageRoomStatistic.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const URL = apiUrl + '/statistics/message-rooms';

const getMessageRoomStatisticFromApi = async (query) => {
  const url = `${URL}?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getMessageRoomStatistic(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getMessageRoomStatisticFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: FETCH_MESSAGE_ROOM_STATISTIC_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: FETCH_MESSAGE_ROOM_STATISTIC_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: FETCH_MESSAGE_ROOM_STATISTIC_FAILED, payload: e });
  }
}

export default function* messageRoomStatisticSaga() {
  yield all([
    takeLatest(FETCH_MESSAGE_ROOM_STATISTIC, getMessageRoomStatistic),
  ]);
}
