import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_LANGUAGES_SYSTEM,
  GET_LANGUAGES_SYSTEM_FAILED,
  GET_LANGUAGES_SYSTEM_SUCCESS,
  CREATE_LANGUAGE_SYSTEM,
  CREATE_LANGUAGE_SYSTEM_FAILED,
  UPDATE_LANGUAGE_SYSTEM,
  UPDATE_LANGUAGE_SYSTEM_FAILED,
  DELETE_LANGUAGE_SYSTEM,
  DELETE_LANGUAGE_SYSTEM_FAILED,
} from "../actions/languageSystemManagement.action";
import { apiRequestFilePost, requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getLanguagesSystemFromApi = async (query) => {
  const url = `${apiUrl}/languages-system?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getLanguagesSystem(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getLanguagesSystemFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_LANGUAGES_SYSTEM_SUCCESS,
        payload: { ...result.body, ...payload },
      });
    } else {
      yield put({ type: GET_LANGUAGES_SYSTEM_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_LANGUAGES_SYSTEM_FAILED, payload: e });
  }
}

const createLanguageSystemFromApi = async (data) => {
  const url = `${apiUrl}/languages-system`;
  const { uploadIcon, ...rest } = data;

  return apiRequestFilePost(
    url,
    { icon: uploadIcon.image },
    { ...rest },
    "POST"
  );
};

function* createLanguageSystem(action) {
  try {
    const result = yield call(() =>
      createLanguageSystemFromApi(action.payload)
    );
    if (result.status === 201) {
      yield put({
        type: GET_LANGUAGES_SYSTEM,
      });
      toast.success("Create language system success");
    } else {
      yield put({ type: CREATE_LANGUAGE_SYSTEM_FAILED, payload: result.error });
      toast.error("Create language system failed");
    }
  } catch (e) {
    yield put({ type: CREATE_LANGUAGE_SYSTEM_FAILED, payload: e });
    toast.error("Create language system failed");
  }
}

const updateLanguageSystemFromApi = async (id, data) => {
  const url = `${apiUrl}/languages-system/${id}`;
  const { uploadIcon } = data;
  const uploadFiles = {};

  if (uploadIcon) {
    uploadFiles.icon = uploadIcon.image;
    delete data.uploadIcon;
  }

  return apiRequestFilePost(url, uploadFiles, data, "PUT");
};

function* updateLanguageSystem(action) {
  try {
    const { payload, languageSystemId } = action;
    const result = yield call(() =>
      updateLanguageSystemFromApi(languageSystemId, payload)
    );
    if (result.status === 200) {
      yield put({
        type: GET_LANGUAGES_SYSTEM,
      });
      toast.success("Update language system success");
    } else {
      yield put({ type: UPDATE_LANGUAGE_SYSTEM, payload: result.error });
      toast.error("Update language system failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_LANGUAGE_SYSTEM, payload: e });
    toast.error("Update language system failed");
  }
}

const deleteLanguageSystemFromApi = async (id) => {
  const url = `${apiUrl}/languages-system/${id}`;
  return requestApi(url, "DELETE");
};

function* deleteLanguageSystem(action) {
  try {
    const { payload, languageSystemId } = action;
    const result = yield call(() =>
      deleteLanguageSystemFromApi(languageSystemId, payload)
    );
    if (result.status === 200) {
      yield put({
        type: GET_LANGUAGES_SYSTEM,
      });
      toast.success("Delete language system success");
    } else {
      yield put({ type: DELETE_LANGUAGE_SYSTEM_FAILED, payload: result.error });
      toast.error("Delete language system failed");
    }
  } catch (e) {
    yield put({ type: DELETE_LANGUAGE_SYSTEM_FAILED, payload: e });
    toast.error("Delete language system failed");
  }
}

export default function* degreesSaga() {
  yield all([
    takeLatest(GET_LANGUAGES_SYSTEM, getLanguagesSystem),
    takeLatest(CREATE_LANGUAGE_SYSTEM, createLanguageSystem),
    takeLatest(UPDATE_LANGUAGE_SYSTEM, updateLanguageSystem),
    takeLatest(DELETE_LANGUAGE_SYSTEM, deleteLanguageSystem),
  ]);
}
