export const GET_PACKAGES = "[PACKAGE] GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "[PACKAGE] GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAILED = "[PACKAGE] GET_PACKAGES_FAILED";

export const CREATE_PACKAGE = "[PACKAGE] CREATE_PACKAGE";
export const CREATE_PACKAGE_SUCCESS = "[PACKAGE] CREATE_PACKAGE_SUCCESS";
export const CREATE_PACKAGE_FAILED = "[PACKAGE] CREATE_PACKAGE_FAILED";

export const UPDATE_PACKAGE = "[PACKAGE] UPDATE_PACKAGE";
export const UPDATE_PACKAGE_SUCCESS = "[PACKAGE] UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_FAILED = "[PACKAGE] UPDATE_PACKAGE_FAILED";

export const DELETE_PACKAGE = "[PACKAGE] DELETE_PACKAGE";
export const DELETE_PACKAGE_SUCCESS = "[PACKAGE] DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAILED = "[PACKAGE] DELETE_PACKAGE_FAILED";
