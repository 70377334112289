import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_FAILED,
  GET_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_FAILED,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_FAILED,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_FAILED,
} from "../actions/campaignManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getListFromApi = async (query) => {
  const url = `${apiUrl}/campaigns?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getList(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getListFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_CAMPAIGNS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_CAMPAIGNS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_CAMPAIGNS_FAILED, payload: e });
  }
}


const createFromApi = async (data) => {
  const url = `${apiUrl}/campaigns`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* create(action) {
  try {
    const result = yield call(() => createFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_CAMPAIGNS,
      });
      toast.success("Create campaign success");
    } else {
      yield put({ type: CREATE_CAMPAIGN_FAILED, payload: result.error });
      toast.error("Create campaign failed");
    }
  } catch (e) {
    yield put({ type: CREATE_CAMPAIGN_FAILED, payload: e });
    toast.error("Create campaign failed");
  }
}

const updateFromApi = async (id, data) => {
  const url = `${apiUrl}/campaigns/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* update(action) {
  try {
    const { payload, id } = action;
    const result = yield call(() => updateFromApi(id, payload));
    if (result.status === 200) {
      yield put({
        type: GET_CAMPAIGNS,
      });
      toast.success("Update campaign success");
    } else {
      yield put({ type: UPDATE_CAMPAIGN_FAILED, payload: result.error });
      toast.error("Update campaign failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_CAMPAIGN_FAILED, payload: e });
    toast.error("Update campaign failed");
  }
}

const deleteFromApi = async (id) => {
  const url = `${apiUrl}/campaigns/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteCampaign(action) {
  try {
    const { payload, id } = action;
    const result = yield call(() => deleteFromApi(id, payload));
    if (result.status === 200) {
      yield put({
        type: GET_CAMPAIGNS,
      });
      toast.success("Delete campaign success");
    } else {
      yield put({ type: DELETE_CAMPAIGN_FAILED, payload: result.error });
      toast.error("Delete campaign failed");
    }
  } catch (e) {
    yield put({ type: DELETE_CAMPAIGN_FAILED, payload: e });
    toast.error("Delete campaign failed");
  }
}

export default function* campaignsSaga() {
  yield all([
    takeLatest(GET_CAMPAIGNS, getList),
    takeLatest(CREATE_CAMPAIGN, create),
    takeLatest(UPDATE_CAMPAIGN, update),
    takeLatest(DELETE_CAMPAIGN, deleteCampaign),
  ]);
}
