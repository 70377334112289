export const GET_USER_LIST = "[USER_MANAGEMENT] GET_USERS";
export const GET_USER_LIST_SUCCESS = "[USER_MANAGEMENT] GET_USERS_SUCCESS";
export const GET_USER_LIST_FAILED = "[USER_MANAGEMENT] GET_USERS_FAILED";
export const CLEAR_USER_MANAGEMENT_STATE =
  "[USER_MANAGEMENT] CLEAR_USER_MANAGEMENT_STATE";

export const DELETE_USER = "[USER_MANAGEMENT] DELETE_USER";
export const DELETE_USER_SUCCESS = "[USER_MANAGEMENT] DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "[USER_MANAGEMENT] DELETE_USER_FAILED";

export const SEND_EMAIL_USER = "[USER_MANAGEMENT] SEND_EMAIL_USER";
export const SEND_EMAIL_USER_SUCCESS = "[USER_MANAGEMENT] SEND_EMAIL_USER_SUCCESS";
export const SEND_EMAIL_USER_FAILED = "[USER_MANAGEMENT] SEND_EMAIL_USER_FAILED";
