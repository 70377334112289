import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_PACKAGES,
  GET_PACKAGES_FAILED,
  GET_PACKAGES_SUCCESS,
  CREATE_PACKAGE,
  CREATE_PACKAGE_FAILED,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_FAILED,
  DELETE_PACKAGE,
  DELETE_PACKAGE_FAILED,
} from "../actions/packageManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getPackagesFromApi = async (query) => {
  const url = `${apiUrl}/packages?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getPackages(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getPackagesFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_PACKAGES_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_PACKAGES_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_PACKAGES_FAILED, payload: e });
  }
}


const createPackageFromApi = async (data) => {
  const url = `${apiUrl}/packages`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createPackage(action) {
  try {
    const result = yield call(() => createPackageFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_PACKAGES,
      });
      toast.success("Create package success");
    } else {
      yield put({ type: CREATE_PACKAGE_FAILED, payload: result.error });
      toast.error("Create package failed");
    }
  } catch (e) {
    yield put({ type: CREATE_PACKAGE_FAILED, payload: e });
    toast.error("Create package failed");
  }
}

const updatePackageFromApi = async (id, data) => {
  const url = `${apiUrl}/packages/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updatePackage(action) {
  try {
    const { payload, packageId } = action;
    const result = yield call(() => updatePackageFromApi(packageId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_PACKAGES,
      });
      toast.success("Update package success");
    } else {
      yield put({ type: UPDATE_PACKAGE_FAILED, payload: result.error });
      toast.error("Update package failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_PACKAGE_FAILED, payload: e });
    toast.error("Update package failed");
  }
}

const deletePackageFromApi = async (id) => {
  const url = `${apiUrl}/packages/${id}`;
  return requestApi(url, 'DELETE');
};

function* deletePackage(action) {
  try {
    const { payload, packageId } = action;
    const result = yield call(() => deletePackageFromApi(packageId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_PACKAGES,
      });
      toast.success("Delete package success");
    } else {
      yield put({ type: DELETE_PACKAGE_FAILED, payload: result.error });
      toast.error("Delete package failed");
    }
  } catch (e) {
    yield put({ type: DELETE_PACKAGE_FAILED, payload: e });
    toast.error("Delete package failed");
  }
}

export default function* packagesSaga() {
  yield all([
    takeLatest(GET_PACKAGES, getPackages),
    takeLatest(CREATE_PACKAGE, createPackage),
    takeLatest(UPDATE_PACKAGE, updatePackage),
    takeLatest(DELETE_PACKAGE, deletePackage),
  ]);
}
