export const GET_RATE_OPTIONS = "[RATE_OPTION] GET_RATE_OPTIONS";
export const GET_RATE_OPTIONS_SUCCESS = "[RATE_OPTION] GET_RATE_OPTIONS_SUCCESS";
export const GET_RATE_OPTIONS_FAILED = "[RATE_OPTION] GET_RATE_OPTIONS_FAILED";

export const CREATE_RATE_OPTION = "[RATE_OPTION] CREATE_RATE_OPTION";
export const CREATE_RATE_OPTION_SUCCESS = "[RATE_OPTION] CREATE_RATE_OPTION_SUCCESS";
export const CREATE_RATE_OPTION_FAILED = "[RATE_OPTION] CREATE_RATE_OPTION_FAILED";

export const UPDATE_RATE_OPTION = "[RATE_OPTION] UPDATE_RATE_OPTION";
export const UPDATE_RATE_OPTION_SUCCESS = "[RATE_OPTION] UPDATE_RATE_OPTION_SUCCESS";
export const UPDATE_RATE_OPTION_FAILED = "[RATE_OPTION] UPDATE_RATE_OPTION_FAILED";

export const DELETE_RATE_OPTION = "[RATE_OPTION] DELETE_RATE_OPTION";
export const DELETE_RATE_OPTION_SUCCESS = "[RATE_OPTION] DELETE_RATE_OPTION_SUCCESS";
export const DELETE_RATE_OPTION_FAILED = "[RATE_OPTION] DELETE_RATE_OPTION_FAILED";
