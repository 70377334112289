export const GET_DEGREES = "[DEGREE] GET_DEGREES";
export const GET_DEGREES_SUCCESS = "[DEGREE] GET_DEGREES_SUCCESS";
export const GET_DEGREES_FAILED = "[DEGREE] GET_DEGREES_FAILED";

export const CREATE_DEGREE = "[DEGREE] CREATE_DEGREE";
export const CREATE_DEGREE_SUCCESS = "[DEGREE] CREATE_DEGREE_SUCCESS";
export const CREATE_DEGREE_FAILED = "[DEGREE] CREATE_DEGREE_FAILED";

export const UPDATE_DEGREE = "[DEGREE] UPDATE_DEGREE";
export const UPDATE_DEGREE_SUCCESS = "[DEGREE] UPDATE_DEGREE_SUCCESS";
export const UPDATE_DEGREE_FAILED = "[DEGREE] UPDATE_DEGREE_FAILED";

export const DELETE_DEGREE = "[DEGREE] DELETE_DEGREE";
export const DELETE_DEGREE_SUCCESS = "[DEGREE] DELETE_DEGREE_SUCCESS";
export const DELETE_DEGREE_FAILED = "[DEGREE] DELETE_DEGREE_FAILED";
