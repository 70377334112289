import {
  GET_USER_GRAPH,
  GET_USER_GRAPH_FAILED,
  GET_USER_GRAPH_SUCCESS,
  GET_USER_ACTIVE_GRAPH,
  GET_USER_ACTIVE_GRAPH_FAILED,
  GET_USER_ACTIVE_GRAPH_SUCCESS
} from "../actions/userGraph.action";

const INITIAL_STATE = {
  userGraph: {
    data: {},
    query: null,
  },
  userActiveGraph: {
    data: {},
    query: null,
  },
  error: {},
  loading: false,
};

export default function userGraphReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_GRAPH:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_USER_GRAPH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        userGraph: {
          ...payload,
        },
      });
    case GET_USER_GRAPH_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case GET_USER_ACTIVE_GRAPH:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_USER_ACTIVE_GRAPH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        userActiveGraph: {
          ...payload,
        },
      });
    case GET_USER_ACTIVE_GRAPH_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
