import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_FAILED,
  GET_CAMPAIGNS_SUCCESS,
} from "../actions/campaignManagement.action";

const INITIAL_STATE = {
  campaigns: {
    data: {},
    total: 0,
    page: 1,
    search: '',
  },
  error: {},
  loading: false,
};

export default function campaignReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CAMPAIGNS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_CAMPAIGNS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        campaigns: {
          ...payload,
          data: payload.data,
        },
      });
    case GET_CAMPAIGNS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
