import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_DEGREES,
  GET_DEGREES_FAILED,
  GET_DEGREES_SUCCESS,
  CREATE_DEGREE,
  CREATE_DEGREE_FAILED,
  UPDATE_DEGREE,
  UPDATE_DEGREE_FAILED,
  DELETE_DEGREE,
  DELETE_DEGREE_FAILED,
} from "../actions/degreeManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getDegreesFromApi = async (query) => {
  const url = `${apiUrl}/degrees?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getDegrees(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getDegreesFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_DEGREES_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_DEGREES_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_DEGREES_FAILED, payload: e });
  }
}


const createDegreeFromApi = async (data) => {
  const url = `${apiUrl}/degrees`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createDegree(action) {
  try {
    const result = yield call(() => createDegreeFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_DEGREES,
      });
      toast.success("Create degree success");
    } else {
      yield put({ type: CREATE_DEGREE_FAILED, payload: result.error });
      toast.error("Create degree failed");
    }
  } catch (e) {
    yield put({ type: CREATE_DEGREE_FAILED, payload: e });
    toast.error("Create degree failed");
  }
}

const updateDegreeFromApi = async (id, data) => {
  const url = `${apiUrl}/degrees/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updateDegree(action) {
  try {
    const { payload, degreeId } = action;
    const result = yield call(() => updateDegreeFromApi(degreeId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_DEGREES,
      });
      toast.success("Update degree success");
    } else {
      yield put({ type: UPDATE_DEGREE_FAILED, payload: result.error });
      toast.error("Update degree failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_DEGREE_FAILED, payload: e });
    toast.error("Update degree failed");
  }
}

const deleteDegreeFromApi = async (id) => {
  const url = `${apiUrl}/degrees/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteDegree(action) {
  try {
    const { payload, degreeId } = action;
    const result = yield call(() => deleteDegreeFromApi(degreeId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_DEGREES,
      });
      toast.success("Delete degree success");
    } else {
      yield put({ type: DELETE_DEGREE_FAILED, payload: result.error });
      toast.error("Delete degree failed");
    }
  } catch (e) {
    yield put({ type: DELETE_DEGREE_FAILED, payload: e });
    toast.error("Delete degree failed");
  }
}

export default function* degreesSaga() {
  yield all([
    takeLatest(GET_DEGREES, getDegrees),
    takeLatest(CREATE_DEGREE, createDegree),
    takeLatest(UPDATE_DEGREE, updateDegree),
    takeLatest(DELETE_DEGREE, deleteDegree),
  ]);
}
