export const GET_LIST_INFORMATION = "[INFORMATION] GET_LIST_INFORMATION";
export const GET_LIST_INFORMATION_SUCCESS = "[INFORMATION] GET_LIST_INFORMATION_SUCCESS";
export const GET_LIST_INFORMATION_FAILED = "[INFORMATION] GET_LIST_INFORMATION_FAILED";

export const CREATE_INFORMATION = "[INFORMATION] CREATE_INFORMATION";
export const CREATE_INFORMATION_SUCCESS = "[INFORMATION] CREATE_INFORMATION_SUCCESS";
export const CREATE_INFORMATION_FAILED = "[INFORMATION] CREATE_INFORMATION_FAILED";

export const UPDATE_INFORMATION = "[INFORMATION] UPDATE_INFORMATION";
export const UPDATE_INFORMATION_SUCCESS = "[INFORMATION] UPDATE_INFORMATION_SUCCESS";
export const UPDATE_INFORMATION_FAILED = "[INFORMATION] UPDATE_INFORMATION_FAILED";

export const DELETE_INFORMATION = "[INFORMATION] DELETE_INFORMATION";
export const DELETE_INFORMATION_SUCCESS = "[INFORMATION] DELETE_INFORMATION_SUCCESS";
export const DELETE_INFORMATION_FAILED = "[INFORMATION] DELETE_INFORMATION_FAILED";
