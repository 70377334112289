import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_SUMMARY_USERS,
  GET_SUMMARY_USERS_SUCCESS,
  GET_SUMMARY_USERS_FAILED,

  GET_ACTIVE_USERS,
  GET_ACTIVE_USERS_SUCCESS,
  GET_ACTIVE_USERS_FAILED,

  GET_SUMMARY_JOBS,
  GET_SUMMARY_JOBS_FAILED,
  GET_SUMMARY_JOBS_SUCCESS,

  GET_JOBS_BY_STATUS,
  GET_JOBS_BY_STATUS_SUCCESS,
  GET_JOBS_BY_STATUS_FAILED,

  GET_SUMMARY_PRICE,
  GET_SUMMARY_PRICE_FAILED,
  GET_SUMMARY_PRICE_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS,
  GET_SUMMARY_PRICE_BY_STATUS_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS,
  GET_SUMMARY_REVENUE_BY_STATUS_SUCCESS,
  GET_SUMMARY_REVENUE_BY_STATUS_FAILED,

  GET_DASHBOARD_SUMMARY_USER,
  GET_DASHBOARD_SUMMARY_USER_FAILED,
  GET_DASHBOARD_SUMMARY_USER_SUCCESS,

  GET_DASHBOARD_SUMMARY_GIG,
  GET_DASHBOARD_SUMMARY_GIG_FAILED,
  GET_DASHBOARD_SUMMARY_GIG_SUCCESS,

  GET_DASHBOARD_SUMMARY_TRANSACTION,
  GET_DASHBOARD_SUMMARY_TRANSACTION_FAILED,
  GET_DASHBOARD_SUMMARY_TRANSACTION_SUCCESS,

  GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED,
  GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_FAILED,
  GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_SUCCESS,

  GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG,
  GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_FAILED,
  GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_SUCCESS,

  GET_ACTIVE_USERS_DAILY,
  GET_ACTIVE_USERS_DAILY_SUCCESS,
  GET_ACTIVE_USERS_DAILY_FAILED,

  GET_ACTIVE_USERS_MONTHLY,
  GET_ACTIVE_USERS_MONTHLY_SUCCESS,
  GET_ACTIVE_USERS_MONTHLY_FAILED,

  GET_ACTIVE_USERS_WEEKLY,
  GET_ACTIVE_USERS_WEEKLY_SUCCESS,
  GET_ACTIVE_USERS_WEEKLY_FAILED,

  GET_JOBS_BY_STATUS_DAILY,
  GET_JOBS_BY_STATUS_DAILY_FAILED,
  GET_JOBS_BY_STATUS_DAILY_SUCCESS,

  GET_JOBS_BY_STATUS_WEEKLY,
  GET_JOBS_BY_STATUS_WEEKLY_FAILED,
  GET_JOBS_BY_STATUS_WEEKLY_SUCCESS,

  GET_JOBS_BY_STATUS_MONTHLY,
  GET_JOBS_BY_STATUS_MONTHLY_FAILED,
  GET_JOBS_BY_STATUS_MONTHLY_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS_DAILY,
  GET_SUMMARY_PRICE_BY_STATUS_DAILY_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_DAILY_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS_WEEKLY,
  GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_SUCCESS,

  GET_SUMMARY_PRICE_BY_STATUS_MONTHLY,
  GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_FAILED,
  GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_HOURLY,
  GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_DAILY,
  GET_SUMMARY_REVENUE_BY_STATUS_DAILY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_DAILY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY,
  GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_SUCCESS,

  GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY,
  GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_FAILED,
  GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_SUCCESS,

  GET_ALL_SUMMARY_REVENUE,
  GET_ALL_SUMMARY_REVENUE_FAILED,
  GET_ALL_SUMMARY_REVENUE_SUCCESS,

} from "../actions/dashboard.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getSummaryUserFromApi = async () => {
  const url = `${apiUrl}/dashboard/users/summary`;
  return requestApi(url, "GET");
};

function* getSummaryUser(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryUserFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_USERS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_USERS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_USERS_FAILED, payload: e });
  }
}

const getSummaryActiveUserFromApi = async (query) => {
  const url = `${apiUrl}/dashboard/users/summary-active?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getSummaryActiveUser(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryActiveUserFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_ACTIVE_USERS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_ACTIVE_USERS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_ACTIVE_USERS_FAILED, payload: e });
  }
}

function* getSummaryActiveUserDaily(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryActiveUserFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_ACTIVE_USERS_DAILY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_ACTIVE_USERS_DAILY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_ACTIVE_USERS_DAILY_FAILED, payload: e });
  }
}

function* getSummaryActiveUserWeekly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryActiveUserFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_ACTIVE_USERS_WEEKLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_ACTIVE_USERS_WEEKLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_ACTIVE_USERS_WEEKLY_FAILED, payload: e });
  }
}

function* getSummaryActiveUserMonthly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryActiveUserFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_ACTIVE_USERS_MONTHLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_ACTIVE_USERS_MONTHLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_ACTIVE_USERS_MONTHLY_FAILED, payload: e });
  }
}

const getSummaryJobsFromApi = async () => {
  const url = `${apiUrl}/dashboard/jobs/summary`;
  return requestApi(url, "GET");
};

function* getSummaryJobs(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryJobsFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_JOBS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_JOBS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_JOBS_FAILED, payload: e });
  }
}


const getJobsByStatusFromApi = async (query) => {
  const url = `${apiUrl}/dashboard/jobs/summary-status?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getJobsByStatus(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getJobsByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_JOBS_BY_STATUS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_JOBS_BY_STATUS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_JOBS_BY_STATUS_FAILED, payload: e });
  }
}

const getSummaryPriceFromApi = async (query) => {
  const url = `${apiUrl}/dashboard/prices/summary`;
  return requestApi(url, "GET");
};

function* getSummaryPrice(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryPriceFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_PRICE_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_PRICE_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_PRICE_FAILED, payload: e });
  }
}

const getSummaryPriceByStatusFromApi = async (query) => {
  const url = `${apiUrl}/dashboard/prices/summary-status?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getSummaryPriceByStatus(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryPriceByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_PRICE_BY_STATUS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_FAILED, payload: e });
  }
}


const getSummaryRevenueByStatusFromApi = async (query) => {
  const url = `${apiUrl}/dashboard/revenue/summary-status?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getSummaryRevenueByStatus(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryRevenueByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_REVENUE_BY_STATUS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_FAILED, payload: e });
  }
}


const getAllSummaryRevenueFromApi = async () => {
  const url = `${apiUrl}/dashboard/revenue/all-summary`;
  return requestApi(url, "GET");
};

function* getAllSummaryRevenue(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getAllSummaryRevenueFromApi());
    if (result.status === 200) {
      yield put({
        type: GET_ALL_SUMMARY_REVENUE_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_ALL_SUMMARY_REVENUE_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_ALL_SUMMARY_REVENUE_FAILED, payload: e });
  }
}

/**
 * Dashboard Summary
 */

const getDashboardSummaryUserFromApi = async () => {
  const url = `${apiUrl}/dashboard/summary/users`;
  return requestApi(url, "GET");
};

function* getDashboardSummaryUser(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getDashboardSummaryUserFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_DASHBOARD_SUMMARY_USER_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_DASHBOARD_SUMMARY_USER_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_DASHBOARD_SUMMARY_USER_FAILED, payload: e });
  }
}

const getDashboardSummaryGigFromApi = async () => {
  const url = `${apiUrl}/dashboard/summary/gigs`;
  return requestApi(url, "GET");
};

function* getDashboardSummaryGig(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getDashboardSummaryGigFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_DASHBOARD_SUMMARY_GIG_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_DASHBOARD_SUMMARY_GIG_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_DASHBOARD_SUMMARY_GIG_FAILED, payload: e });
  }
}

const getDashboardSummaryTransactionFromApi = async () => {
  const url = `${apiUrl}/dashboard/summary/transactions`;
  return requestApi(url, "GET");
};

function* getDashboardSummaryTransaction(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getDashboardSummaryTransactionFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_DASHBOARD_SUMMARY_TRANSACTION_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_DASHBOARD_SUMMARY_TRANSACTION_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_DASHBOARD_SUMMARY_TRANSACTION_FAILED, payload: e });
  }
}

const getDashboardSummaryTransactionClosedFromApi = async () => {
  const url = `${apiUrl}/dashboard/summary/transactions-closed`;
  return requestApi(url, "GET");
};

function* getDashboardSummaryTransactionClosed(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getDashboardSummaryTransactionClosedFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED_FAILED, payload: e });
  }
}

const getDashboardSummarySellerCreatedGigFromApi = async () => {
  const url = `${apiUrl}/dashboard/summary/seller-created-gigs`;
  return requestApi(url, "GET");
};

function* getDashboardSummarySellerCreatedGig(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getDashboardSummarySellerCreatedGigFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG_FAILED, payload: e });
  }
}

function* getJobsByStatusDaily(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getJobsByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_JOBS_BY_STATUS_DAILY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_JOBS_BY_STATUS_DAILY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_JOBS_BY_STATUS_DAILY_FAILED, payload: e });
  }
}

function* getJobsByStatusWeekly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getJobsByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_JOBS_BY_STATUS_WEEKLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_JOBS_BY_STATUS_WEEKLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_JOBS_BY_STATUS_WEEKLY_FAILED, payload: e });
  }
}

function* getJobsByStatusMonthly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getJobsByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_JOBS_BY_STATUS_MONTHLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_JOBS_BY_STATUS_MONTHLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_JOBS_BY_STATUS_MONTHLY_FAILED, payload: e });
  }
}

function* getSummaryPriceByStatusDaily(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryPriceByStatusFromApi(payload));
    console.log({ result })
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_PRICE_BY_STATUS_DAILY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_DAILY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_DAILY_FAILED, payload: e });
  }
}

function* getSummaryPriceByStatusWeekly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryPriceByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_WEEKLY_FAILED, payload: e });
  }
}

function* getSummaryPriceByStatusMonthly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryPriceByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_PRICE_BY_STATUS_MONTHLY_FAILED, payload: e });
  }
}

function* getSummaryRevenueByStatusHourly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryRevenueByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_HOURLY_FAILED, payload: e });
  }
}

function* getSummaryRevenueByStatusDaily(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryRevenueByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_REVENUE_BY_STATUS_DAILY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_DAILY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_DAILY_FAILED, payload: e });
  }
}

function* getSummaryRevenueByStatusWeekly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryRevenueByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY_FAILED, payload: e });
  }
}

function* getSummaryRevenueByStatusMonthly(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getSummaryRevenueByStatusFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY_FAILED, payload: e });
  }
}












export default function* dashboardSaga() {
  yield all([
    takeLatest(GET_SUMMARY_USERS, getSummaryUser),
    takeLatest(GET_ACTIVE_USERS, getSummaryActiveUser),
    takeLatest(GET_ACTIVE_USERS_DAILY, getSummaryActiveUserDaily),
    takeLatest(GET_ACTIVE_USERS_WEEKLY, getSummaryActiveUserWeekly),
    takeLatest(GET_ACTIVE_USERS_MONTHLY, getSummaryActiveUserMonthly),
    takeLatest(GET_SUMMARY_JOBS, getSummaryJobs),
    takeLatest(GET_JOBS_BY_STATUS, getJobsByStatus),
    takeLatest(GET_SUMMARY_PRICE, getSummaryPrice),
    takeLatest(GET_SUMMARY_PRICE_BY_STATUS, getSummaryPriceByStatus),
    takeLatest(GET_SUMMARY_REVENUE_BY_STATUS, getSummaryRevenueByStatus),
    takeLatest(GET_DASHBOARD_SUMMARY_USER, getDashboardSummaryUser),
    takeLatest(GET_DASHBOARD_SUMMARY_GIG, getDashboardSummaryGig),
    takeLatest(GET_DASHBOARD_SUMMARY_TRANSACTION, getDashboardSummaryTransaction),
    takeLatest(GET_DASHBOARD_SUMMARY_TRANSACTION_CLOSED, getDashboardSummaryTransactionClosed),
    takeLatest(GET_DASHBOARD_SUMMARY_SELLER_CREATED_GIG, getDashboardSummarySellerCreatedGig),
    takeLatest(GET_JOBS_BY_STATUS_DAILY, getJobsByStatusDaily),
    takeLatest(GET_JOBS_BY_STATUS_WEEKLY, getJobsByStatusWeekly),
    takeLatest(GET_JOBS_BY_STATUS_MONTHLY, getJobsByStatusMonthly),
    takeLatest(GET_SUMMARY_PRICE_BY_STATUS_DAILY, getSummaryPriceByStatusDaily),
    takeLatest(GET_SUMMARY_PRICE_BY_STATUS_WEEKLY, getSummaryPriceByStatusWeekly),
    takeLatest(GET_SUMMARY_PRICE_BY_STATUS_MONTHLY, getSummaryPriceByStatusMonthly),
    takeLatest(GET_SUMMARY_REVENUE_BY_STATUS_HOURLY, getSummaryRevenueByStatusHourly),
    takeLatest(GET_SUMMARY_REVENUE_BY_STATUS_DAILY, getSummaryRevenueByStatusDaily),
    takeLatest(GET_SUMMARY_REVENUE_BY_STATUS_WEEKLY, getSummaryRevenueByStatusWeekly),
    takeLatest(GET_SUMMARY_REVENUE_BY_STATUS_MONTHLY, getSummaryRevenueByStatusMonthly),
    takeLatest(GET_ALL_SUMMARY_REVENUE, getAllSummaryRevenue),
  ]);
}
