import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  FETCH_LIST_TRACKING_FAILED,
  FETCH_LIST_TRACKING_SUCCESS,
  FETCH_LIST_TRACKING,
} from "../actions/trackingManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getListTrackingFromApi = async (query) => {
  const url = `${apiUrl}/tracking?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getListTracking(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getListTrackingFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: FETCH_LIST_TRACKING_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: FETCH_LIST_TRACKING_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: FETCH_LIST_TRACKING_FAILED, payload: e });
  }
}

export default function* trackingSaga() {
  yield all([
    takeLatest(FETCH_LIST_TRACKING, getListTracking),
  ]);
}
