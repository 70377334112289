import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILED,
  CREATE_COUNTRY,
  CREATE_COUNTRY_FAILED,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_FAILED,
  DELETE_COUNTRY,
  DELETE_COUNTRY_FAILED,
} from "../actions/countryManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getCountriesFromApi = async (query) => {
  const url = `${apiUrl}/countries?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getCountries(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getCountriesFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_COUNTRIES_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_COUNTRIES_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_COUNTRIES_FAILED, payload: e });
  }
}


const createCountryFromApi = async (data) => {
  const url = `${apiUrl}/countries`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* createCountry(action) {
  try {
    const result = yield call(() => createCountryFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_COUNTRIES,
      });
      toast.success("Create country success");
    } else {
      yield put({ type: CREATE_COUNTRY_FAILED, payload: result.error });
      toast.error("Create country failed");
    }
  } catch (e) {
    yield put({ type: CREATE_COUNTRY_FAILED, payload: e });
    toast.error("Create country failed");
  }
}

const updateCountryFromApi = async (id, data) => {
  const url = `${apiUrl}/countries/${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* updateCountry(action) {
  try {
    const { payload, countryId } = action;
    const result = yield call(() => updateCountryFromApi(countryId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_COUNTRIES,
      });
      toast.success("Update country success");
    } else {
      yield put({ type: UPDATE_COUNTRY_FAILED, payload: result.error });
      toast.error("Update country failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_COUNTRY_FAILED, payload: e });
    toast.error("Update country failed");
  }
}

const deleteCountryFromApi = async (id) => {
  const url = `${apiUrl}/countries/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteCountry(action) {
  try {
    const { payload, countryId } = action;
    const result = yield call(() => deleteCountryFromApi(countryId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_COUNTRIES,
      });
      toast.success("Delete country success");
    } else {
      yield put({ type: DELETE_COUNTRY_FAILED, payload: result.error });
      toast.error("Delete country failed");
    }
  } catch (e) {
    yield put({ type: DELETE_COUNTRY_FAILED, payload: e });
    toast.error("Delete country failed");
  }
}

export default function* countriesSaga() {
  yield all([
    takeLatest(GET_COUNTRIES, getCountries),
    takeLatest(CREATE_COUNTRY, createCountry),
    takeLatest(UPDATE_COUNTRY, updateCountry),
    takeLatest(DELETE_COUNTRY, deleteCountry),
  ]);
}
