import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  SEND_EMAIL_TO_USER,
  SEND_EMAIL_TO_USER_SUCCESS,
  SEND_EMAIL_TO_USER_FAILED,
  FETCH_HISTORY_SENT_EMAIL,
  FETCH_HISTORY_SENT_EMAIL_SUCCESS,
  FETCH_HISTORY_SENT_EMAIL_FAILED,
  DELETE_HISTORY_SENT_EMAIL,
  DELETE_HISTORY_SENT_EMAIL_FAILED,
} from "../actions/sendEmailManagement.action";
import {apiRequestFilePost, requestApi} from "../utils/requestHelper";
import { apiUrl } from "../config/config";
import {get} from "lodash";

const sendEmailToUserApi = async (data) => {
  const url = `${apiUrl}/send-email-to-users`;
  const to = get(data, 'to.file');
  delete data.to;
  return apiRequestFilePost(
    url,
    { to },
    data,
    "POST"
  );
};

function* sendEmailToUser(action) {
  try {
    const { payload} = action;
    const result = yield call(() => sendEmailToUserApi(payload));
    if (result.status === 200) {
      yield put({
        type: SEND_EMAIL_TO_USER_SUCCESS,
      });
      toast.success("Send Email success");
      window.location.href = '/send-email-management';
    } else {
      yield put({ type: SEND_EMAIL_TO_USER_FAILED, payload: result.error });
      toast.error("DSend Email failed");
    }
  } catch (e) {
    yield put({ type: SEND_EMAIL_TO_USER_FAILED, payload: e });
    toast.error("Send Email failed");
  }
}

const getListHistoryFromApi = async (params) => {
  const url = `${apiUrl}/send-email-to-users?${new URLSearchParams({ limit: 10, ...params })}`;
  return requestApi(url, "GET");
};

function* getListHistory(action) {
  try {
    const result = yield call(() => getListHistoryFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: FETCH_HISTORY_SENT_EMAIL_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: FETCH_HISTORY_SENT_EMAIL_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: FETCH_HISTORY_SENT_EMAIL_FAILED, payload: e });
  }
}

const deleteHistoryFromApi = async (id) => {
  const url = `${apiUrl}/send-email-to-users/histories/${id}`;
  return requestApi(url, "DELETE");
};

function* deleteHistory(action) {
  try {
    const result = yield call(() => deleteHistoryFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: FETCH_HISTORY_SENT_EMAIL,
      });
    } else {
      yield put({ type: DELETE_HISTORY_SENT_EMAIL_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: DELETE_HISTORY_SENT_EMAIL_FAILED, payload: e });
  }
}

export default function* sendEmailManagementSaga() {
  yield all([
    takeLatest(SEND_EMAIL_TO_USER, sendEmailToUser),
    takeLatest(FETCH_HISTORY_SENT_EMAIL, getListHistory),
    takeLatest(DELETE_HISTORY_SENT_EMAIL, deleteHistory),
  ]);
}
