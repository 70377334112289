import {
  GET_ORDER_LIST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_FAILED,
  CLEAR_ORDER_MANAGEMENT_STATE,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAILED,
  CANCEL_ORDER_DETAIL,
} from "../actions/orderManagement.action";

const INITIAL_STATE = {
  orderList: {
    data: [],
    total: 0,
    page: 1,
    status: "",
    lateStatus: "",
    search: "",
  },
  orderDetail: {},
  showDetailModal: false,
  error: {},
  loading: false,
};

export default function orderManagementReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_LIST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ORDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        orderList: payload,
      });
    case GET_ORDER_LIST_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case CLEAR_ORDER_MANAGEMENT_STATE:
      return INITIAL_STATE;
    case GET_ORDER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        orderDetail: payload,
        showDetailModal: true,
      });
    case GET_ORDER_DETAIL_FAILED:
      return Object.assign({}, state, {
        error: payload,
      });
    case CANCEL_ORDER_DETAIL:
      return Object.assign({}, state, {
        orderDetail: {},
        showDetailModal: false,
      });
    default:
      return state;
  }
}
