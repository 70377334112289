import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_LIST_INFORMATION,
  GET_LIST_INFORMATION_FAILED,
  GET_LIST_INFORMATION_SUCCESS,
  CREATE_INFORMATION,
  CREATE_INFORMATION_FAILED,
  UPDATE_INFORMATION,
  UPDATE_INFORMATION_FAILED,
  DELETE_INFORMATION,
  DELETE_INFORMATION_FAILED,
} from "../actions/informationManagement.action";
import {apiRequestFilePost, requestApi} from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getListInformationFromApi = async (query) => {
  const url = `${apiUrl}/information?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getListInformation(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getListInformationFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_LIST_INFORMATION_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_LIST_INFORMATION_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_LIST_INFORMATION_FAILED, payload: e });
  }
}


const createInformationFromApi = async (data) => {
  const url = `${apiUrl}/information`;
  const { uploadImage, ...rest } = data;
  const uploadFiles = {};

  if (uploadImage) {
    uploadFiles.image = uploadImage.image;
    delete data.uploadImage;
  }
  return apiRequestFilePost(
    url,
    uploadFiles,
    { ...rest },
    'POST'
  );
};

function* createInformation(action) {
  try {
    const result = yield call(() => createInformationFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_LIST_INFORMATION,
      });
      toast.success("Create information success");
    } else {
      yield put({ type: CREATE_INFORMATION_FAILED, payload: result.error });
      toast.error("Create information failed");
    }
  } catch (e) {
    console.log({ e })
    yield put({ type: CREATE_INFORMATION_FAILED, payload: e });
    toast.error("Create information failed");
  }
}

const updateInformationFromApi = async (id, data) => {
  const url = `${apiUrl}/information/${id}`;
  const { uploadImage } = data;
  const uploadFiles = {};

  if (uploadImage) {
    uploadFiles.image = uploadImage.image;
    delete data.uploadImage;
  }

  return apiRequestFilePost(url, uploadFiles, data, "PUT");
};

function* updateInformation(action) {
  try {
    const { payload, informationId } = action;
    const result = yield call(() => updateInformationFromApi(informationId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_LIST_INFORMATION
      });
      toast.success("Update information success");
    } else {
      yield put({ type: UPDATE_INFORMATION_FAILED, payload: result.error });
      toast.error("Update information failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_INFORMATION_FAILED, payload: e });
    toast.error("Update information failed");
  }
}

const deleteInformationFromApi = async (id) => {
  const url = `${apiUrl}/information/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteInformation(action) {
  try {
    const { payload, informationId } = action;
    const result = yield call(() => deleteInformationFromApi(informationId, payload));
    if (result.status === 200) {
      yield put({
        type: GET_LIST_INFORMATION,
      });
      toast.success("Delete information success");
    } else {
      yield put({ type: DELETE_INFORMATION_FAILED, payload: result.error });
      toast.error("Delete information failed");
    }
  } catch (e) {
    yield put({ type: DELETE_INFORMATION_FAILED, payload: e });
    toast.error("Delete information failed");
  }
}

export default function* informationSaga() {
  yield all([
    takeLatest(GET_LIST_INFORMATION, getListInformation),
    takeLatest(CREATE_INFORMATION, createInformation),
    takeLatest(UPDATE_INFORMATION, updateInformation),
    takeLatest(DELETE_INFORMATION, deleteInformation),
  ]);
}
