import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_BLACKLISTS,
  GET_BLACKLISTS_FAILED,
  GET_BLACKLISTS_SUCCESS,
  CREATE_BLACKLIST,
  CREATE_BLACKLIST_FAILED,
  UPDATE_BLACKLIST,
  UPDATE_BLACKLIST_FAILED,
  DELETE_BLACKLIST,
  DELETE_BLACKLIST_FAILED,
} from "../actions/blacklistManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const URL = apiUrl + '/blacklists';

const getListFromApi = async (query) => {
  const url = `${URL}?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getList(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getListFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: GET_BLACKLISTS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_BLACKLISTS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_BLACKLISTS_FAILED, payload: e });
  }
}


const createFromApi = async (data) => {
  const url = `${URL}`;
  return requestApi(url, 'POST', JSON.stringify(data));
};

function* create(action) {
  try {
    const result = yield call(() => createFromApi(action.payload));
    if (result.status === 201) {
      yield put({
        type: GET_BLACKLISTS,
      });
      toast.success("Create blacklist success");
    } else {
      yield put({ type: CREATE_BLACKLIST_FAILED, payload: result.error });
      toast.error("Create blacklist failed");
    }
  } catch (e) {
    yield put({ type: CREATE_BLACKLIST_FAILED, payload: e });
    toast.error("Create blacklist failed");
  }
}

const updateFromApi = async (id, data) => {
  const url = `${URL}//${id}`;
  return requestApi(url, 'PUT', JSON.stringify(data));
};

function* update(action) {
  try {
    const { payload, id } = action;
    const result = yield call(() => updateFromApi(id, payload));
    if (result.status === 200) {
      yield put({
        type: GET_BLACKLISTS,
      });
      toast.success("Update blacklist success");
    } else {
      yield put({ type: UPDATE_BLACKLIST_FAILED, payload: result.error });
      toast.error("Update blacklist failed");
    }
  } catch (e) {
    yield put({ type: UPDATE_BLACKLIST_FAILED, payload: e });
    toast.error("Update blacklist failed");
  }
}

const deleteFromApi = async (id) => {
  const url = `${URL}/${id}`;
  return requestApi(url, 'DELETE');
};

function* deleteBlacklist(action) {
  try {
    const { payload, id } = action;
    const result = yield call(() => deleteFromApi(id, payload));
    if (result.status === 200) {
      yield put({
        type: GET_BLACKLISTS,
      });
      toast.success("Delete blacklist success");
    } else {
      yield put({ type: DELETE_BLACKLIST_FAILED, payload: result.error });
      toast.error("Delete blacklist failed");
    }
  } catch (e) {
    yield put({ type: DELETE_BLACKLIST_FAILED, payload: e });
    toast.error("Delete blacklist failed");
  }
}

export default function* blacklistsSaga() {
  yield all([
    takeLatest(GET_BLACKLISTS, getList),
    takeLatest(CREATE_BLACKLIST, create),
    takeLatest(UPDATE_BLACKLIST, update),
    takeLatest(DELETE_BLACKLIST, deleteBlacklist),
  ]);
}
