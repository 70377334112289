import {
  GET_KEYWORDS,
  GET_KEYWORDS_SUCCESS,
  GET_KEYWORDS_FAILED,

  IMPORT_KEYWORD,
  IMPORT_KEYWORD_SUCCESS,
  IMPORT_KEYWORD_FAILED,
} from "../actions/keyword.action";

const INITIAL_STATE = {
  keywords: {
    data: [],
    total: 0,
    page: 1,
    search: '',
  },
  error: {},
  loading: false,
};

export default function keywordReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_KEYWORDS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_KEYWORDS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        keywords: payload,
      });
    case GET_KEYWORDS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });

    case IMPORT_KEYWORD:
      return Object.assign({}, state, {
        loading: true,
      });

    case IMPORT_KEYWORD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });

    case IMPORT_KEYWORD_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
