import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
} from "../actions/user.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

function* login(data) {
  const { body, history } = data;
  try {
    const userLogin = yield call(loginHandle.bind(this, body));
    if (userLogin.status === 200) {
      yield put({ type: USER_LOGIN_SUCCESS, payload: userLogin });
      localStorage.setItem("jwt", userLogin.message.accessToken);
      history.push("/");
    } else {
      yield put({ type: USER_LOGIN_FAILED, payload: userLogin.error });
    }
  } catch (e) {
    yield put({ type: USER_LOGIN_FAILED, payload: e });
  }
}

async function loginHandle(data) {
  const url = `${apiUrl}/login`;
  return requestApi(url, "POST", JSON.stringify(data));
}

export default function* watchUserSagasAsync() {
  yield all([takeLatest(USER_LOGIN, login)]);
}
