export const GET_LEVELS = "[LEVEL] GET_LEVELS";
export const GET_LEVELS_SUCCESS = "[LEVEL] GET_LEVELS_SUCCESS";
export const GET_LEVELS_FAILED = "[LEVEL] GET_LEVELS_FAILED";

export const CREATE_LEVEL = "[LEVEL] CREATE_LEVEL";
export const CREATE_LEVEL_SUCCESS = "[LEVEL] CREATE_LEVEL_SUCCESS";
export const CREATE_LEVEL_FAILED = "[LEVEL] CREATE_LEVEL_FAILED";

export const UPDATE_LEVEL = "[LEVEL] UPDATE_LEVEL";
export const UPDATE_LEVEL_SUCCESS = "[LEVEL] UPDATE_LEVEL_SUCCESS";
export const UPDATE_LEVEL_FAILED = "[LEVEL] UPDATE_LEVEL_FAILED";

export const DELETE_LEVEL = "[LEVEL] DELETE_LEVEL";
export const DELETE_LEVEL_SUCCESS = "[LEVEL] DELETE_LEVEL_SUCCESS";
export const DELETE_LEVEL_FAILED = "[LEVEL] DELETE_LEVEL_FAILED";
