import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import userManagementReducer from "./userManagement.reducer";
import gigManagementReducer from "./gigManagement.reducer";
import offerManagementReducer from "./offerManagement.reducer";
import orderManagementReducer from "./orderManagement.reducer";
import categoryManagementReducer from "./categoryManagement.reducer";
import keywordReducer from "./keyword.reducer";
import tagManagementReducer from "./tagManagement.reducer";
import languageManagementReducer from "./languageManagement.reducer";
import levelManagementReducer from "./levelManagement.reducer";
import countryManagementReducer from "./countryManagement.reducer";
import cityManagementReducer from "./cityManagement.reducer";
import uiManagementReducer from "./uiManagement.reducer";
import degreeManagementReducer from "./degreeManagement.reducer";
import languageSystemManagementReducer from "./languageSystemManagement.reducer";
import rateOptionManagementReducer from "./rateOptionManagement.reducer";
import packageManagementReducer from "./packageManagement.reducer";
import informationManagementReducer from "./informationManagement.reducer";
import currencyManagementReducer from "./currencyManagement.reducer";
import newRegisterReducer from './newRegisterManagement.reducer';
import dashboardReducer from './dashboard.reducer';
import userGraphReducer from './userGraph.reducer';
import campaignReducer from './campaignManagement.reducer';
import blacklistReducer from './blacklistManagement.reducer';
import sendEmailManagementReducer from './sendEmailManagement';
import trackingManagementReducer from './trackingManagement.reducer';
import trackingAnalysisReducer from './trackingAnalysis.reducer';
import messageRoomStatisticReducer from './messageRoomStatistic.reducer';

const reducer = combineReducers({
  routing: routerReducer,
  user: userReducer,
  userManagement: userManagementReducer,
  gigManagement: gigManagementReducer,
  offerManagement: offerManagementReducer,
  orderManagement: orderManagementReducer,
  categoryManagement: categoryManagementReducer,
  keywordManagement: keywordReducer,
  tagManagement: tagManagementReducer,
  languageManagement: languageManagementReducer,
  levelManagement: levelManagementReducer,
  countryManagement: countryManagementReducer,
  cityManagement: cityManagementReducer,
  uiManagement: uiManagementReducer,
  degreeManagement: degreeManagementReducer,
  languageSystemManagement: languageSystemManagementReducer,
  rateOptionManagement: rateOptionManagementReducer,
  packageManagement: packageManagementReducer,
  informationManagement: informationManagementReducer,
  currencyManagement: currencyManagementReducer,
  newRegisterManagement: newRegisterReducer,
  dashboardReducer: dashboardReducer,
  userGraph: userGraphReducer,
  campaignManagement: campaignReducer,
  blacklistManagement: blacklistReducer,
  sendEmailManagement: sendEmailManagementReducer,
  trackingManagement: trackingManagementReducer,
  trackingAnalysis: trackingAnalysisReducer,
  messageRoomStatistic: messageRoomStatisticReducer,
});

export default reducer;
