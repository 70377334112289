import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  FETCH_LIST_TRACKING_ANALYSIS,
  FETCH_LIST_TRACKING_ANALYSIS_FAILED,
  FETCH_LIST_TRACKING_ANALYSIS_SUCCESS,
} from "../actions/trackingAnalysis.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";

const getListTrackingAnalysisFromApi = async (query) => {
  const url = `${apiUrl}/tracking-analysis?${new URLSearchParams(query)}`;
  return requestApi(url, "GET");
};

function* getListTrackingAnalysis(action) {
  try {
    const { payload } = action;
    const result = yield call(() => getListTrackingAnalysisFromApi(payload));
    if (result.status === 200) {
      yield put({
        type: FETCH_LIST_TRACKING_ANALYSIS_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: FETCH_LIST_TRACKING_ANALYSIS_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: FETCH_LIST_TRACKING_ANALYSIS_FAILED, payload: e });
  }
}

export default function* trackingSaga() {
  yield all([
    takeLatest(FETCH_LIST_TRACKING_ANALYSIS, getListTrackingAnalysis),
  ]);
}
