import {
  FETCH_MESSAGE_ROOM_STATISTIC,
  FETCH_MESSAGE_ROOM_STATISTIC_FAILED,
  FETCH_MESSAGE_ROOM_STATISTIC_SUCCESS,
} from "../actions/messageRoomStatistic.action";

const INITIAL_STATE = {
  messageRoomStatistic: {
    data: [],
    total: 0,
    page: 1,
    limit: 10
  },
  error: {},
  loading: false,
};

export default function messageRoomStatisticReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MESSAGE_ROOM_STATISTIC:
      return Object.assign({}, state, {
        loading: true,
      });
    case FETCH_MESSAGE_ROOM_STATISTIC_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        messageRoomStatistic: {
          ...payload,
          data: payload.data,
        },
      });
    case FETCH_MESSAGE_ROOM_STATISTIC_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
