import {
  SEND_EMAIL_TO_USER,
  SEND_EMAIL_TO_USER_FAILED,
  SEND_EMAIL_TO_USER_SUCCESS,
  FETCH_HISTORY_SENT_EMAIL_SUCCESS,
} from "../actions/sendEmailManagement.action";

const INITIAL_STATE = {
  histories: {
    data: [],
    total: 0,
    page: 1,
    search: '',
  },
  error: {},
  loading: false,
};

export default function sendEmailManagementReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case SEND_EMAIL_TO_USER:
      return Object.assign({}, state, {
        loading: true,
      });
    case SEND_EMAIL_TO_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case SEND_EMAIL_TO_USER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    case FETCH_HISTORY_SENT_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        histories: payload,
      });
    default:
      return state;
  }
}
