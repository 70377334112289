import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  DELETE_USER,
  // DELETE_USER_SUCCESS,
  // DELETE_USER_FAILED,
  SEND_EMAIL_USER,
  SEND_EMAIL_USER_FAILED,
  SEND_EMAIL_USER_SUCCESS
} from "../actions/userManagement.action";
import { requestApi } from "../utils/requestHelper";
import { apiUrl } from "../config/config";
import { toast } from "react-toastify";

async function getUserListFromApi(params) {
  const url = `${apiUrl}/users?${new URLSearchParams(params)}`;
  return requestApi(url, "GET");
}

function* getUserList(action) {
  try {
    const result = yield call(() => getUserListFromApi(action.payload));
    if (result.status === 200) {
      yield put({
        type: GET_USER_LIST_SUCCESS,
        payload: { ...result.body, ...action.payload },
      });
    } else {
      yield put({ type: GET_USER_LIST_FAILED, payload: result.error });
    }
  } catch (e) {
    yield put({ type: GET_USER_LIST_FAILED, payload: e });
  }
}

const deleteUserFromApi = async (userId) => {
  const url = `${apiUrl}/users/${userId}`;
  return requestApi(url, "DELETE");
};

function* deleteUser(action) {
  try {
    const result = yield call(() => deleteUserFromApi(action.payload.userId));
    if (result.status === 200) {
      toast.success("Delete user success!");
      yield put({
        type: GET_USER_LIST,
        payload: action.payload.params,
      });
    } else {
      // yield put({ type: GET_USER_LIST_FAILED, payload: result.error });
      toast.error("Delete user failed!");
    }
  } catch (e) {
    yield put({ type: GET_USER_LIST_FAILED, payload: e });
  }
}


const sendEmailToUserApi = async (data) => {
  const url = `${apiUrl}/send-email-marketing`;
  return requestApi(url, "POST", JSON.stringify(data));
};

function* sendEmailToUser(action) {
  try {
    const { payload } = action;
    const result = yield call(() => sendEmailToUserApi(payload));
    if (result.status === 200) {
      yield put({
        type: SEND_EMAIL_USER_SUCCESS,
      });
      toast.success(`Total ${result.body.total} email will send`);
    } else {
      yield put({ type: SEND_EMAIL_USER_FAILED, payload: result.error });
      toast.error("Send Email failed");
    }
  } catch (e) {
    yield put({ type: SEND_EMAIL_USER_FAILED, payload: e });
    toast.error("Send Email failed");
  }
}

export default function* userManagementSaga() {
  yield all([
    takeLatest(GET_USER_LIST, getUserList),
    takeLatest(DELETE_USER, deleteUser),
    takeLatest(SEND_EMAIL_USER, sendEmailToUser),
  ]);
}
