export const GET_BLACKLISTS = "[BLACKLIST] GET_BLACKLISTS";
export const GET_BLACKLISTS_SUCCESS = "[BLACKLIST] GET_BLACKLISTS_SUCCESS";
export const GET_BLACKLISTS_FAILED = "[BLACKLIST] GET_BLACKLISTS_FAILED";

export const CREATE_BLACKLIST = "[BLACKLIST] CREATE_BLACKLIST";
export const CREATE_BLACKLIST_SUCCESS = "[BLACKLIST] CREATE_BLACKLIST_SUCCESS";
export const CREATE_BLACKLIST_FAILED = "[BLACKLIST] CREATE_BLACKLIST_FAILED";

export const UPDATE_BLACKLIST = "[BLACKLIST] UPDATE_BLACKLIST";
export const UPDATE_BLACKLIST_SUCCESS = "[BLACKLIST] UPDATE_BLACKLIST_SUCCESS";
export const UPDATE_BLACKLIST_FAILED = "[BLACKLIST] UPDATE_BLACKLIST_FAILED";

export const DELETE_BLACKLIST = "[BLACKLIST] DELETE_BLACKLIST";
export const DELETE_BLACKLIST_SUCCESS = "[BLACKLIST] DELETE_BLACKLIST_SUCCESS";
export const DELETE_BLACKLIST_FAILED = "[BLACKLIST] DELETE_BLACKLIST_FAILED";
