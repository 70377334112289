export const SEND_EMAIL_TO_USER = "[SEND_EMAIL] SEND_EMAIL_TO_USER";
export const SEND_EMAIL_TO_USER_SUCCESS = "[SEND_EMAIL] SEND_EMAIL_TO_USER_SUCCESS";
export const SEND_EMAIL_TO_USER_FAILED = "[SEND_EMAIL] SEND_EMAIL_TO_USER_FAILED";

export const FETCH_HISTORY_SENT_EMAIL = "[SEND_EMAIL] FETCH_HISTORY_SENT_EMAIL";
export const FETCH_HISTORY_SENT_EMAIL_SUCCESS = "[SEND_EMAIL] FETCH_HISTORY_SENT_EMAIL_SUCCESS";
export const FETCH_HISTORY_SENT_EMAIL_FAILED = "[SEND_EMAIL] FETCH_HISTORY_SENT_EMAIL_FAILED";

export const DELETE_HISTORY_SENT_EMAIL = "[SEND_EMAIL] DELETE_HISTORY_SENT_EMAIL";
export const DELETE_HISTORY_SENT_EMAIL_SUCCESS = "[SEND_EMAIL] DELETE_HISTORY_SENT_EMAIL_SUCCESS";
export const DELETE_HISTORY_SENT_EMAIL_FAILED = "[SEND_EMAIL] DELETE_HISTORY_SENT_EMAIL_FAILED";
